import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react-web';
import animation from './lottie-thread.json';
import { Text } from '@cyberpion/cyberpion-ui';
import { ThreatsCenterContextType, useThreatsCenter } from 'pages/ThreatCenter/ThreatsCenterContext';
import { AppLoading } from 'common/components/appLoading/appLoading';

const options = {
  animationData: animation,
  loop: false
};

const ThreatCenterHeader = () => {
  const [findings, setFindings] = useState<any>(0);
  const [potentially, setPotentially] = useState<any>(0);
  const { dependentData, isLoadingDependentData } = useThreatsCenter() as ThreatsCenterContextType;

  useEffect(() => {
    if (!!dependentData && !!Object.keys(dependentData).length) {
      let findingsCount = 0;
      let potentialCount = 0;
      Object.values(dependentData).forEach((item: any) => {
        findingsCount += item.findings !== 'err' ? item.findings || 0 : 0;
        potentialCount += item.potential_affected !== 'err' ? item.potential_affected || 0 : 0;
      });
      setFindings(findingsCount);
      setPotentially(potentialCount);
    }
  }, [dependentData]);

  return (
    <div
      style={{
        backgroundColor: '#000000',
        display: 'flex',
        alignItems: 'center'
      }}
      className="ThreatCenterHeader"
    >
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24, marginTop: 12, whiteSpace: 'nowrap' }}>
        <Text textColor="#E8E8E8">Confirmed findings</Text>
        {isLoadingDependentData ? (
          <AppLoading size="small" />
        ) : (
          <Text textSize={32} weight={500} textColor="#FFF" family="Rajdhani">
            {findings}
          </Text>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24, marginTop: 12, whiteSpace: 'nowrap' }}>
        <Text textColor="#E8E8E8">Potentially affected</Text>
        {isLoadingDependentData ? (
          <AppLoading size="small" />
        ) : (
          <Text textSize={32} weight={500} textColor="#FFF" family="Rajdhani">
            {potentially}
          </Text>
        )}
      </div>
      <div style={{ flexGrow: 1, height: 80 }}></div>
      <div
        style={{
          height: 80,
          backgroundImage: `url('/assets/images/threat-center-header.svg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <Lottie options={options} />
      </div>
    </div>
  );
};

export default ThreatCenterHeader;
