import React from 'react';
export class IconUncknowledge extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          d="M10 2H5V3.5C5 3.77614 4.77614 4 4.5 4C4.22386 4 4 3.77614 4 3.5V2C4 1.44772 4.44772 1 5 1H10C10.5523 1 11 1.44772 11 2V7C11 7.55228 10.5523 8 10 8H8.5C8.22386 8 8 7.77614 8 7.5C8 7.22386 8.22386 7 8.5 7H10V2Z"
          fill="#5D6576"
        />
        <path
          d="M2.5 4.5L2 4.5C1.72386 4.5 1.5 4.72386 1.5 5L1.5 5.5"
          stroke="#5D6576"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 10.5L7 10.5C7.27614 10.5 7.5 10.2761 7.5 10L7.5 9.5"
          stroke="#5D6576"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 9.5L1.5 10C1.5 10.2761 1.72386 10.5 2 10.5L2.5 10.5"
          stroke="#5D6576"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="4.5" cy="10.5" r="0.5" fill="#5D6576" />
        <circle cx="1.5" cy="7.5" r="0.5" fill="#5D6576" />
        <path d="M6.5 7.5L4.5 7.5L4.5 5.5" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 7.5L7.5 4.5" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
}
