import React from 'react';
export class IconWarning extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="76" height="24" viewBox="0 0 76 24" fill="none">
        <path
          d="M72 16C72 20.4183 68.4183 24 64 24L12 24C7.58173 24 4 20.4183 4 16L4 3.99999C4 1.79085 2.20914 -6.451e-06 2.09815e-06 -6.64413e-06L76 0C73.7909 -1.93128e-07 72 1.79085 72 3.99997L72 16Z"
          fill="#E06915"
        ></path>
        <path
          d="M10.234 16.763L15.5598 6.87221C15.7485 6.52161 16.2515 6.52161 16.4402 6.87221L21.766 16.7629C21.9454 17.0961 21.7041 17.5 21.3258 17.5H10.6742C10.2959 17.5 10.0546 17.0961 10.234 16.763Z"
          stroke="white"
        ></path>
        <path d="M16 10.5V13.5" stroke="white"></path>
        <path d="M16 14.5V15.5" stroke="white"></path>
        <text
          x="25"
          y="17"
          fill="white"
          style={{
            fontSize: '1rem'
          }}
        >
          Warning
        </text>
      </svg>
    );
  }
}
