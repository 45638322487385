// import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
// import { PopUp } from 'common/components/popUp/PopUp';
// import { setDeletePromptParams } from 'features/GridSlice';
// import { useEffect, useRef, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { AddUserPopUp } from './AddUserPopUp/AddUserPopUp';
// import useAccountPlanLimitations, { PLAN_LIMITATIONS_TYPES } from 'common/hooks/useAccountPlanLimitations';
// import { Text } from '@cyberpion/cyberpion-ui';
// import Tooltip from '@mui/material/Tooltip';
// import classNames from 'classnames';
// import { IReduxState } from 'app/store';

// import './UserManagementActions.scss';

// const iconAlert = (type: string = '') => {
//   const color = type === 'warning' ? '#F7991F' : '#F46666';
//   return (
//     <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path
//         d="M1.78555 9.63147L5.77988 2.21342C5.87427 2.03812 6.12573 2.03812 6.22012 2.21342L10.2145 9.63148C10.3041 9.79803 10.1835 10 9.99434 10L2.00566 10C1.8165 10 1.69586 9.79803 1.78555 9.63147Z"
//         stroke={color}
//       />
//       <path d="M6 4.875V7.125" stroke={color} />
//       <path d="M6 7.875V8.625" stroke={color} />
//     </svg>
//   );
// };

// export function UserManagementActions(props: any) {
//   const dispatch = useDispatch<any>();
//   const ref = useRef<HTMLDivElement>(null);
//   const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
//   const [popupVisible, setPopupVisible] = useState<boolean>(false);
//   const { count } = useSelector((state: IReduxState) => state.grid);
//   const { planLimitation, limitationsType } = useAccountPlanLimitations('limitSubsidiaries');
//   const [limitationsText, setLimitationsText] = useState('');
//   const [tooltipContent, setTooltipContent] = useState<any>(null);

//   const handleClickOutside = (event: Event) => {
//     if (ref.current && !ref.current.contains(event.target as Node)) {
//       setActionsMenuOpen(false);
//       document.removeEventListener('click', handleClickOutside, true);
//     }
//   };

//   useEffect(() => {
//     if (!!planLimitation && limitationsType === PLAN_LIMITATIONS_TYPES.LIMITED) {
//       setLimitationsText(`(${count}/${planLimitation})`);
//       let tt: any = null;
//       if (count >= planLimitation) {
//         tt = (
//           <div style={{ display: 'flex', flexDirection: 'column' }}>
//             <Text style={{ paddingBottom: 4 }} weight={500}>
//               Limit Exceeded
//             </Text>
//             <Text>Adding user creation limit reached (Service Tier Limit).</Text>
//             <Text>Remove an existing user or contact customer success for additional information.</Text>
//           </div>
//         );
//       } else if (count < planLimitation && count >= planLimitation - 2) {
//         tt = (
//           <div style={{ display: 'flex', flexDirection: 'column' }}>
//             <Text style={{ paddingBottom: 4 }} weight={500}>
//               {`Group Limit (${count}/${planLimitation})`}
//             </Text>
//             <Text
//               textSize={13}
//             >{`You have added ${count} out of ${planLimitation} users. If you want more users contact customer success for additional information`}</Text>
//           </div>
//         );
//       }
//       setTooltipContent(tt);
//     }
//   }, [planLimitation, limitationsType, count]);

//   useEffect(() => {
//     document.addEventListener('click', handleClickOutside, true);
//     return () => {
//       document.removeEventListener('click', handleClickOutside, true);
//     };
//   });

//   const onRemove = () => {
//     dispatch(
//       setDeletePromptParams({
//         category: 'users',
//         tab: 'user-management',
//         index: null,
//         ids: props.ids,
//         message: 'Are you sure you would like to remove the following users?'
//       })
//     );
//   };

//   return (
//     <div className="UserManagementActions" ref={ref}>
//       <button
//         className={classNames(
//           'btn btn-main btn-add-user',
//           { disabled: count >= planLimitation },
//           { 'regular-border-radius': count < planLimitation - 2 }
//         )}
//         onClick={() => setPopupVisible(true)}
//       >
//         {`Add New User ${limitationsText}`}
//       </button>
//       {!!tooltipContent && (
//         <Tooltip
//           title={tooltipContent}
//           placement="top-start"
//           componentsProps={{
//             tooltip: {
//               sx: {
//                 bgcolor: '#FFF',
//                 color: '#FFFFFF',
//                 padding: '6px 8px',
//                 fontSize: 13,
//                 boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.12) !important',
//                 '& .MuiTooltip-arrow': {
//                   color: '#4D4C53'
//                 },
//                 borderRadius: '4px'
//               }
//             }
//           }}
//         >
//           <div className={classNames('icon-info-add-user', { disabled: count >= planLimitation })}>
//             {iconAlert(count < planLimitation ? 'warning' : 'error')}
//           </div>
//         </Tooltip>
//       )}
//       <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
//         <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
//           Actions
//           <IconArrow />
//         </div>
//         <ul>
//           <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
//             Remove
//           </li>
//         </ul>
//       </span>
//       <PopUp visible={popupVisible} setVisible={() => setPopupVisible(false)}>
//         <AddUserPopUp onClose={() => setPopupVisible(false)} />
//       </PopUp>
//     </div>
//   );
// }

import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { PopUp } from 'common/components/popUp/PopUp';
import { setDeletePromptParams } from 'features/GridSlice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddUserPopUp } from './AddUserPopUp/AddUserPopUp';
import './UserManagementActions.scss';

export function UserManagementActions(props: any) {
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    dispatch(
      setDeletePromptParams({
        category: 'users',
        tab: 'user-management',
        index: null,
        ids: props.ids,
        message: 'Are you sure you would like to remove the following users?'
      })
    );
  };

  return (
    <div className="UserManagementActions" ref={ref}>
      <button className="btn btn-main" onClick={() => setPopupVisible(true)}>
        Add New User
      </button>
      <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
        <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
          Actions
          <IconArrow />
        </div>
        <ul>
          <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
            Remove
          </li>
        </ul>
      </span>
      <PopUp visible={popupVisible} setVisible={() => setPopupVisible(false)}>
        <AddUserPopUp onClose={() => setPopupVisible(false)} />
      </PopUp>
    </div>
  );
}
