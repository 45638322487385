import { IconAcknowledgeHide } from 'common/components/Icons/IconAcknowledgeHide/IconAcknowledgeHide';
import { IconAddTag } from 'common/components/Icons/IconAddTag/IconAddTag';
import { IconRemoveTag } from 'common/components/Icons/IconRemoveTag/IconRemoveTag';
import { IconRescan } from 'common/components/Icons/IconRescan/IconRescan';
import { IconUncknowledge } from 'common/components/Icons/IconUncknowledge/IconUncknowledge';
import { PathNames } from 'common/constants/pathNames';
interface ITabset {
  [key: string]: ITab;
}
interface ITab {
  title: string;
  key: string;
  link: string;
  permissionOpen: boolean;
  active: boolean;
  showSummary?: boolean;
  columnPickerPath: string;
  gridPath: string;
  tabPath: string;
  count: number;
  savedViews?: boolean;
  defaultView?: string;
}

export const TABSET: ITabset = {
  open: {
    title: 'Open Action Items',
    key: 'open_action_items',
    link: '/pages/actionItems/open',
    defaultView:
      '/pages/actionItems/open?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Cis_acknowledged%2Ctags%2Cgroups&filters=%7B"is_acknowledged"%3A0%7D',
    permissionOpen: true,
    active: false,
    showSummary: true,
    columnPickerPath: PathNames.actionItemsOpenColumns,
    gridPath: PathNames.actionItemsOpen,
    tabPath: PathNames.actionItemsOpenSummary,
    count: 0,
    savedViews: true
  },
  closed: {
    title: 'Closed Action Items',
    key: 'closed_action_items',
    link: '/pages/actionItems/closed',
    defaultView:
      '/pages/actionItems/closed?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Clast_closed_at%2Cresolution_time%2Cis_acknowledged%2Ctags%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: true,
    columnPickerPath: PathNames.actionItemsCloseColumns,
    gridPath: PathNames.actionItemsClose,
    tabPath: PathNames.actionItemsCloseSummary,
    count: 0,
    savedViews: true
  }
};

export const OPTIONS = [
  {
    IconComponent: IconRescan,
    label: 'Rescan'
  },
  {
    IconComponent: IconAcknowledgeHide,
    label: 'Acknowledge'
  },
  {
    IconComponent: IconUncknowledge,
    label: 'Unacknowledge'
  },
  {
    IconComponent: IconAddTag,
    label: 'Add Tags',
    ff: 'isCustomTags'
  },
  {
    IconComponent: IconRemoveTag,
    label: 'Remove Tags',
    ff: 'isCustomTags'
  }
];
