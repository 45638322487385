import React from 'react';
import { Text, IconRisk } from '@cyberpion/cyberpion-ui';
import { IconImportance } from 'common/components/Icons/IconImportance/IconImportance';
import { Grid } from '@mui/material';

import './actionItemHeader.scss';

interface IActionItemHeader {
  actionItem: any;
}

const ActionItemHeader: React.FC<IActionItemHeader> = ({ actionItem }) => {
  const { id, urgency, title, importance, host, type, groups_names } = actionItem;

  return (
    <Grid container alignItems="center">
      <Grid item className="action-item-risk">
        <IconRisk urgency={urgency?.type} />
      </Grid>
      <Grid item className="action-item-urgency">
        <Text textSize={30} weight={400} family="Rajdhani">
          {urgency?.urgency}
        </Text>
      </Grid>
      <Grid item>
        <Grid container flexDirection="column">
          <Grid item id={id}>
            <Text upper textSize={12} weight={600} style={{ lineHeight: 1.5 }}>
              {title?.label}
            </Text>
          </Grid>
          <Grid item className="action-item-header-data">
            <Grid
              container
              alignItems="center"
              sx={!importance ? { paddingTop: '6px', fontSize: 11 } : { fontSize: 11 }}
            >
              {!!importance && (
                <Grid item className="action-item-header-importance">
                  <IconImportance type={importance?.type} />
                </Grid>
              )}
              <Grid item className="action-item-header-host">
                {host}
              </Grid>
              <Grid item className="action-item-header-type">
                {type}
              </Grid>
              <Grid item className="action-item-header-groups">
                {groups_names?.join(', ')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionItemHeader;
