import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { TextField } from '@mui/material';
import classNames from 'classnames';
import { Text } from '@cyberpion/cyberpion-ui';
import { IconMenu } from 'common/components/Icons/IconMenu/IconMenu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

const iconSave = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="8" fill="#3659DE" />
    <path d="M11 8L8.00938 5L5 8" fill="#3659DE" />
    <path d="M11 8L8.00938 5L5 8" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 5L8 11" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const data = [
  {
    email: 'portal_otp+xerox@cyberpion.com',
    name: 'Eran Elkin',
    comment: 'Comment by Eran Elkin',
    date: '2024-02-26T00:33:00.000000Z'
  },
  { email: 'eran@ionix.io', name: 'Nadav Levi', comment: 'Comment by Nadav Levi', date: '2023-01-20T03:22:00.000000Z' },
  {
    email: 'portal_otp+xerox@cyberpion.com',
    name: 'Nadav Meron',
    comment: 'Comment written by Nadav Meron',
    date: '2022-12-16T05:13:00.000000Z'
  },
  { email: 'eran@ionix.io', name: 'Eran Elkin', comment: 'Comment by Eran Elkin', date: '2024-02-26T00:33:00.000000Z' },
  { email: 'eran@ionix.io', name: 'Nadav Levi', comment: 'Comment by Nadav Levi', date: '2023-01-20T03:22:00.000000Z' },
  {
    email: 'eran@ionix.io',
    name: 'Nadav Meron',
    comment: 'Comment written by Nadav Meron',
    date: '2022-12-16T05:13:00.000000Z'
  },
  { email: 'eran@ionix.io', name: 'Eran Elkin', comment: 'Comment by Eran Elkin', date: '2024-02-26T00:33:00.000000Z' },
  { email: 'eran@ionix.io', name: 'Nadav Levi', comment: 'Comment by Nadav Levi', date: '2023-01-20T03:22:00.000000Z' },
  {
    email: 'portal_otp+xerox@cyberpion.com',
    name: 'Nadav Meron',
    comment: 'Comment written by Nadav Meron',
    date: '2022-12-16T05:13:00.000000Z'
  },
  { email: 'eran@ionix.io', name: 'Eran Elkin', comment: 'Comment by Eran Elkin', date: '2024-02-26T00:33:00.000000Z' },
  { email: 'eran@ionix.io', name: 'Nadav Levi', comment: 'Comment by Nadav Levi', date: '2023-01-20T03:22:00.000000Z' },
  {
    email: 'eran@ionix.io',
    name: 'Nadav Meron',
    comment: 'Comment written by Nadav Meron',
    date: '2022-12-16T05:13:00.000000Z'
  }
];

const stringAvatar = (name: string, isBorder: boolean = true) => {
  return {
    sx: {
      bgcolor: '#ECEDFF',
      color: '#3455DD',
      ...(!!isBorder ? { border: '2px solid #3455DD' } : {})
      // fontWeight: 'bold'
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  };
};

const optionsTime: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};

const optionsMenu = [
  { id: 'edit', label: 'Edit Comment' },
  { id: 'delete', label: 'Delete Comment' }
];

const CommentRow = ({ comment = '' }: { comment?: string }) => {
  const [commentText, setCommentText] = useState(comment);

  const handleSaveComment = () => {
    console.log('Save Comment:', comment);
    setCommentText('');
  };

  return (
    <div className="comment-row">
      <Avatar {...stringAvatar('Kent Dodds')} />
      <div className="comment-input">
        <TextField
          fullWidth
          // label={title}
          placeholder="Write your comment here..."
          className={classNames('input', { 'has-value': !!commentText })}
          value={commentText}
          onChange={e => setCommentText(e.target.value)}
          type={'text'}
          onFocus={(ev: any) => ev.target.select()}
          sx={{
            '& .MuiInputBase-root': { backgroundColor: '#F8F8FA', color: '#25262B', fontSize: 13 },
            '& .MuiInputBase-input': {
              padding: '12px 16px',
              marginRight: 5
            }
          }}
          autoComplete="off"
          inputProps={{
            autoComplete: 'new-password',
            form: {
              autocomplete: 'off'
            }
          }}
          // error={isError}
          // helperText={errorText}
        />
      </div>
      <div className={classNames('comment-row-icon', { disabled: !commentText })} onClick={handleSaveComment}>
        {iconSave}
      </div>
    </div>
  );
};

const PopSideCommentsTab = ({ footerHeight }: { footerHeight: number }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user } = useSelector((state: IReduxState) => state.login);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleItemOnClick = (option: any) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
    if (option.id === 'delete') {
      console.log('XXX: delete', option.id);
    } else {
      console.log('XXX: edit', option.id);
    }
  };

  return (
    <div className="PopSideCommentsTab">
      <CommentRow comment="Comment 1" />
      <div className="comment-rows-wrapper" style={{ height: `calc(100vh - ${footerHeight}px - 470px)` }}>
        {!!data &&
          data.map((item, index) => {
            const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(item.date));
            const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(new Date(item.date));
            const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(item.date));

            return (
              <div className="comment-row-item" key={index}>
                <Avatar {...stringAvatar(item.name, false)} />
                <div className="comment-row-item-data">
                  <Text textSize={13} textColor="#6D6D7A">
                    {`${item.name}, ${m} ${d}, ${y} ${new Intl.DateTimeFormat('en-US', optionsTime).format(
                      new Date(item.date)
                    )}`}
                  </Text>
                  <Text textSize={13} textColor="#25262B" style={{ paddingTop: 6 }}>
                    {item.comment}
                  </Text>
                </div>
                {user.email === item.email && (
                  <div className="comment-menu">
                    <div onClick={handleMenuClick} className={classNames('icon-menu')}>
                      <IconMenu active={false} />
                    </div>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                      {optionsMenu.map((option: any, index: number) => {
                        return (
                          <div key={index}>
                            <MenuItem
                              key={option.id}
                              sx={
                                option.id === 'delete'
                                  ? {
                                      fontSize: 12,
                                      fontFamily: 'Rubik',
                                      color: '#f46666',
                                      padding: '8px 16px',
                                      margin: '0 4px',
                                      borderRadius: '4px'
                                    }
                                  : {
                                      fontSize: 12,
                                      fontFamily: 'Rubik',
                                      padding: '8px 16px',
                                      margin: '0 4px',
                                      borderRadius: '4px'
                                    }
                              }
                              onClick={handleItemOnClick(option)}
                              classes={{ root: 'menu-item' }}
                            >
                              {option.label}
                            </MenuItem>
                          </div>
                        );
                      })}
                    </Menu>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PopSideCommentsTab;
