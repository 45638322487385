import { configureStore } from '@reduxjs/toolkit';
import gridReducer from '../features/GridSlice';
import loginReducer from '../features/LoginSlice';
import groupsReducer from '../features/GroupsSlice';
import subsidiariesReducer from '../features/SubsidiariesSlice';
import columnPickerReducer from '../features/columnPickerSlice';
import generalReducer from '../features/GeneralSlice';
import integrationsReducer from '../features/IntegrationsSlice';
import alertReducer from '../features/AlertSlice';
import infoPanelReducer from '../features/InfoPanelSlice';
import MSSPSlice from 'features/MSSPSlice';
import viewsReducer from '../features/ViewsSlice';
import downloadsReducer from '../features/DownloadsSlice';

export const store = configureStore({
  reducer: {
    grid: gridReducer,
    login: loginReducer,
    groups: groupsReducer,
    subsidiaries: subsidiariesReducer,
    columnPicker: columnPickerReducer,
    general: generalReducer,
    integrations: integrationsReducer,
    alert: alertReducer,
    infoPanel: infoPanelReducer,
    mssp: MSSPSlice,
    views: viewsReducer,
    downloads: downloadsReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export interface IReduxState {
  [key: string]: {
    [key: string]: any;
  };
}

export type AppDispatch = typeof store.dispatch;

export default store;
