import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './tabTechnologies.scss';
import { GridUtilities } from 'common/components/grid/components/gridUtilities/GridUtilities';
import { IconWarning } from 'common/components/Icons/IconWarning/IconWarning';
import { ITechnology, ISwitchTabs } from 'pages/inventory/singleAsset/interface';
import { ManageNotData } from 'common/components/manageNotData/ManageNotData';
import { ArrayRenderer } from 'common/components/grid/components/components-renderer/arrayRenderer/ArrayRenderer';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { getRows } from 'features/GridSlice';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';

export function TabTechnologies(props: ISwitchTabs) {
  // const [items, setItems] = React.useState<ITechnology[]>([]);
  // const [nextPath, setNextPath] = React.useState<undefined | null | string>(
  //   props.path
  // );
  // const [currentPath, setCurrentPath] = React.useState<undefined | null | string>(
  //   props.path
  // );
  const [expanded, setExpanded] = useState<number | null>(null);
  const { rows, nextPath } = useSelector((state: IReduxState) => state.grid);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    rows.forEach((r: ITechnology, i: number) => {
      const el = document.getElementsByClassName(`content-index-${i}`)[0];
      const numOfVisibleLines = 3;
      const lineHeight = 10;
      if (el && el.clientHeight <= numOfVisibleLines * lineHeight) {
        const expandEl = document.getElementsByClassName(`expand-icon-wrapper index-${i}`)[0];
        const borderEl = document.getElementsByClassName(`bottom-border-inside index-${i}`)[0];
        if (expandEl && borderEl) {
          expandEl.remove();
          borderEl.remove();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    // let cancel = false;
    // let cancelResponse = false;
    // //@description: Sends request for information
    // if (cancelResponse) return;
    // RestApi.getPaginatedData(props.path).subscribe(
    //   (response: IResponsePaginatedData) => {
    //     if (cancel) return;
    //     setItems(response.results);
    //     setNextPath(response.next);
    //   }
    // );

    // return () => {
    //   cancelResponse = true;
    //   cancel = true;
    // };
    // dispatch(removeAllRows())
    // if (initialPath.includes('technologies')) {
    dispatch(getRows());
    // }
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('click', setClickOutSideEvent);

    return () => {
      document.removeEventListener('click', setClickOutSideEvent);
    };
  });

  function setClickOutSideEvent(e: any) {
    if (e.target.classList.contains('expand-icon-wrapper') || e.target.closest('.expand-icon-wrapper')) {
      return;
    }
    const el = e.target.closest(`.tabTechnologies-item-scope.index-${expanded}`);
    if (!el) {
      setExpanded(null);
    }
  }

  //@description: Sends request for more data
  function handleOnRowsScrollEnd() {
    if (nextPath) {
      dispatch(getRows());
    }
    // if (nextPath != null) {
    //   let path = Common.splitV1FromPath(nextPath);
    //   RestApi.getPaginatedData(path).subscribe(
    //     (response: IResponsePaginatedData) => {
    //       setItems([...items, ...response.results]);
    //       setNextPath(response.next);
    //     }
    //   );
    // }
  }

  // function handleCallback() {}
  // function handleUpdateGrid() {
  //   // if (currentPath) {
  //   //   let path = `${Common.clearQueryFromPath(currentPath)}${queryGrid.getQueryChain()}`;
  //   //   RestApi.getData(path).subscribe((response: IResponsePaginatedData) => {
  //   //     setItems(response.results);
  //   //     setNextPath(response.next);
  //   //     if (response.next !== null) {
  //   //       setCurrentPath(response.next);
  //   //     }
  //   //   });
  //   // }
  // }

  function manageMessages() {
    return <h4>Loading...</h4>;
  }

  // Returns a shorten app name as a placeholder for an app with no logo.
  // Examples:
  // AWS -> AWS
  // Slack -> Sl
  // Microsoft Teams -> Mt
  const getShortenAppName = (appName: string): string => {
    if (!appName) {
      return '';
    }
    const appNameAsArray = appName.split(' ');

    if (appName.length <= 3) {
      return appName;
    }

    if (appNameAsArray.length > 1) {
      return `${appNameAsArray[0].charAt(0)}${appNameAsArray[1].charAt(0)}`;
    }

    return appName.substring(0, 2);
  };

  const getAppImage = (item: ITechnology): JSX.Element => {
    if (item.logo_url) {
      return <div className="app-logo-image" style={{ backgroundImage: `url('${item.logo_url}')` }} />;
    }

    return <div className="app-custom-logo">{getShortenAppName(item.label)}</div>;
  };

  const expand = (index: number) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
      if (index >= rows.length - 2) {
        // if last 2 items, scroll to the bottom after expanding
        setTimeout(() => {
          const el = document.getElementsByClassName('infinite-scroll-component');
          if (el) {
            el[0].scrollTop = 10000;
          }
        }, 100);
      }
    }
  };

  // function prepareCSVDownloadPath(path: string | null): string | null {
  //   if (path) {
  //     return `${process.env.REACT_APP_BASE_URL}${path}&format=csv`;
  //   }
  //   return null
  // }

  return (
    <div className="tabTechnologies">
      <GridUtilities
        // columnPicker={props.columnPicker}
        // showColumnPicker={props.showColumnPicker}
        // parentCallback={handleCallback}
        // onUpdateFilterGrid={handleUpdateGrid}
        // downloadCSVPath={csvDownloadPath}
        isMoreButton={props.isMoreButton}
        isCSVDownloadable={props.isCSVDownloadable}
      />
      <div className="scrollbar-common tabTechnologies-scope">
        <div id="scrollableDiv" style={{ height: '100%', overflow: 'auto' }}>
          {rows.length === 0 ? (
            <ManageNotData type={props.selectTab} />
          ) : (
            <InfiniteScroll
              dataLength={rows.length}
              next={handleOnRowsScrollEnd}
              hasMore={!!nextPath}
              loader={manageMessages()}
              scrollableTarget="scrollableDiv"
              className="tabTechnologies-items-wrapper"
            >
              {rows.map((item: ITechnology, index: number) => (
                <React.Fragment key={index}>
                  <div
                    style={{ zIndex: rows.length - index }}
                    className={`index-${index} tabTechnologies-item-scope ${expanded === index ? 'expand' : ''}`}
                  >
                    <div onClick={() => expand(index)} className={`expand-icon-wrapper index-${index}`}>
                      <IconArrow />
                    </div>
                    <div className="tabTechnologies-left-side">{getAppImage(item)}</div>
                    <div className="tabTechnologies-right-side">
                      {item.is_vulnerable_component && (
                        <div className="tabTechnologies-warning">
                          <IconWarning />
                        </div>
                      )}
                      <div className="technology-content">
                        <div className="">
                          <span className="tabTechnologies-name">
                            {item.label}
                            <span className="tabTechnologies-version">{item.version && `V${item.version}`}</span>
                          </span>
                        </div>
                        <div className={`content-index-${index}`}>
                          <ArrayRenderer data={item.cves} flexWrap="wrap" />
                          {/* {item.cves.map((cve: string, index: number) => (
                            <div key={index}>{cve}</div>
                          ))} */}
                        </div>
                        <div className={`bottom-border`}>
                          <span className={`bottom-border-inside index-${index}`}>...</span>
                        </div>
                      </div>
                      {/* <div className="tabTechnologies-content">

                    </div>  */}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
}
