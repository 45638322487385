import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ISource } from 'common/components/grid/columnsManage';
import './LinkRenderer.scss';
import { Common } from 'common/services/common.service';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

const DESTINATIONS = {
  INTERNAL: 'internalAssets',
  EXTERNAL: 'digitalSupplyChain',
  ACTION_ITEMS: 'actionItems',
  VULNERABILITIES: 'vulnerabilities',
  TESTS: 'tests',
  MOBILE: 'mobileAssets',
  CONNECTIONS: 'connections'
};

export function LinkRenderer(props: any) {
  const isIOS = props.source.tab === DESTINATIONS.MOBILE && props.params.row.type.toLowerCase().includes('ios');

  const buildLink = (params: GridRenderCellParams, source: ISource) => {
    if (!props.identifier) {
      return <span>{props.params.value}</span>;
    }
    let destination: { path: string; tab: string } = { path: '', tab: '' };

    if (source.page === 'assesments') {
      if (source.tab === DESTINATIONS.EXTERNAL) {
        if (params.field === 'connected_assets') {
          destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.ACTION_ITEMS };
        } else {
          destination = { path: DESTINATIONS.EXTERNAL, tab: DESTINATIONS.VULNERABILITIES };
        }
      } else {
        destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.TESTS };
      }
    } else if (source.page === 'discovery') {
      if (source.tab === DESTINATIONS.EXTERNAL) {
        if (params.field === 'connected_assets') {
          destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.ACTION_ITEMS };
        } else {
          destination = { path: DESTINATIONS.EXTERNAL, tab: DESTINATIONS.VULNERABILITIES };
        }
      } else if (source.tab === DESTINATIONS.MOBILE) {
        destination = {
          path: DESTINATIONS.MOBILE,
          tab: isIOS ? DESTINATIONS.CONNECTIONS : DESTINATIONS.TESTS
        };
      } else {
        destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.ACTION_ITEMS };
      }
    } else if (source.page === 'singleAsset') {
      if (source.category === DESTINATIONS.INTERNAL) {
        if (DESTINATIONS.EXTERNAL.includes(props.params.row.connected_asset_type.toLowerCase())) {
          destination = { path: DESTINATIONS.EXTERNAL, tab: DESTINATIONS.VULNERABILITIES };
        } else {
          if (props.params.row.connected_asset_type?.toLowerCase() === 'external') {
            destination = { path: DESTINATIONS.EXTERNAL, tab: DESTINATIONS.VULNERABILITIES };
          } else {
            destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.ACTION_ITEMS };
          }
        }
      } else if (source.category === DESTINATIONS.MOBILE) {
        if (DESTINATIONS.EXTERNAL.includes(props.params.row.connected_asset_type.toLowerCase())) {
          destination = { path: DESTINATIONS.EXTERNAL, tab: DESTINATIONS.VULNERABILITIES };
        } else {
          destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.ACTION_ITEMS };
        }
      } else {
        destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.ACTION_ITEMS };
      }
    } else if (params.field === 'target') {
      destination = { path: DESTINATIONS.EXTERNAL, tab: DESTINATIONS.VULNERABILITIES };
    } else if (params.field === 'source') {
      destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.ACTION_ITEMS };
    } else {
      destination = { path: DESTINATIONS.INTERNAL, tab: DESTINATIONS.ACTION_ITEMS };
    }

    const id = [DESTINATIONS.EXTERNAL, DESTINATIONS.INTERNAL].includes(destination.path)
      ? props.params.value
      : props.params.id;

    const subAccount = Common.getParamFromUrl('account');
    const linkUrl = `/pages/singleAsset/${destination.path}/${encodeURIComponent(id)}/${destination.tab}${
      subAccount ? `?account=${subAccount}` : ''
    }`;

    return !isIOS ? (
      <LinkNewTab
        to={linkUrl}
        text={<div>{props.params.value}</div>}
        className={'grid-link-field'}
        testid="link-renderer"
      />
    ) : (
      props.params.value
    );
  };

  return buildLink(props.params, props.source);
}
