import React, { useState, useEffect } from 'react';
import { CustomSwitch } from 'common/components/CustomSwitch/CustomSwitch';
import { Text } from '@cyberpion/cyberpion-ui';
import { ThreatsCenterContextType, useThreatsCenter } from '../ThreatsCenterContext';
import { useDispatch } from 'react-redux';
import { showSuccess, showError } from 'features/AlertSlice';

import './ThreatCenterTopBar.scss';
// import Button from '@mui/material/Button';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';

const iconDropdown = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.125 6H3.375L4.5 1.5L7.5 10.5L8.625 6H10.875"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ThreatCenterTopBar = () => {
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);
  const [isLoadingSubscribed, setIsLoadingSubscribed] = useState<boolean>(true);
  const {
    search,
    setSearch,
    showOnlyFindings,
    setShowOnlyFindings,
    setTimelineSelected
  } = useThreatsCenter() as ThreatsCenterContextType;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    RestApi.getData('threat-center/subscription/').subscribe(
      (response: any) => {
        setIsSubscribed(response.subscribed);
        setIsLoadingSubscribed(false);
      },
      error => {
        setIsLoadingSubscribed(false);
      }
    );
  }, []);

  const handleSwitch = (e: React.ChangeEvent, isChecked: boolean) => {
    setTimelineSelected(null);
    setShowOnlyFindings(isChecked);
  };

  const handleOnSearch = (e: any) => {
    setTimelineSelected(null);
    setSearch(e.target.value);
  };

  const handleSubscribe = () => {
    setIsLoadingSubscribed(true);
    RestApi.setData('threat-center/subscription/', { subscribed: !isSubscribed }, 'PATCH').subscribe(
      (response: any) => {
        setIsSubscribed(response.subscribed);
        dispatch(showSuccess('Subscription updated'));
        setIsLoadingSubscribed(false);
      },
      error => {
        dispatch(showError('Something went wrong'));
        setIsLoadingSubscribed(false);
      }
    );
  };

  const tooltipSubscribe = (
    <div>
      By subscribing, you will receive real-time email notifications when a new threat alert is published. See your
      confirmed findings via one-click login to your IONIX account.
    </div>
  );

  return (
    <div className="ThreatCenterTopBar">
      <div className="Search">
        <img alt="Search" src="/assets/images/search.svg" />
        <input id="input-search" type="text" maxLength={30} value={search} onChange={handleOnSearch} />
      </div>

      <div
        className={classNames(
          'threat-center-subscribe',
          { loading: !!isLoadingSubscribed },
          { active: !!isSubscribed }
        )}
        onClick={handleSubscribe}
      >
        {isLoadingSubscribed ? <AppLoading size="small" /> : <div className="subscribe-icon">{iconDropdown}</div>}
        <Text>{!!isSubscribed ? 'Unsubscribe from email updates' : 'Subscribe for email updates'}</Text>
      </div>
      <Tooltip
        title={tooltipSubscribe}
        placement="bottom-start"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: '#4D4C53',
              color: '#FFFFFF',
              padding: '3px 6px',
              '& .MuiTooltip-arrow': {
                color: '#4D4C53'
              },
              border: '2px solid black',
              borderRadius: 1
            }
          }
        }}
      >
        <div>
          <img src="/assets/images/info.svg" alt="info" />
        </div>
      </Tooltip>
      <div className="top-bar-switch">
        <Text textColor="#707070" style={{ marginRight: 12 }}>
          Hide threats without findings
        </Text>
        <CustomSwitch checked={showOnlyFindings} onChange={handleSwitch} />
      </div>
    </div>
  );
};

export default ThreatCenterTopBar;
