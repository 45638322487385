import classNames from 'classnames';
import './AddEntityWidget.scss';
import { IconRadioButton } from 'common/components/Icons/IconRadioButton/IconRadioButton';
import { Input } from 'common/components/Input/Input';
import { useEffect, useRef, useState } from 'react';
import { Common } from 'common/services/common.service';

export function AddEntityWidget(props: any) {
  const [value, setValue] = useState<string>('');
  const [inputType, setInputType] = useState<'name' | 'domain'>('name');
  const [error, setError] = useState<boolean>(false);

  const ref: any = useRef(null);
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      props.onClose();
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div ref={ref} className={classNames('AddEntityWidget', { visible: props.visible })}>
      <h6>Add Asset</h6>
      <ul>
        <li onClick={() => setInputType('name')}>
          <IconRadioButton radioSize="big" toggle={inputType === 'name'} /> Name
        </li>
        <li onClick={() => setInputType('domain')}>
          <IconRadioButton radioSize="big" toggle={inputType === 'domain'} />
          Domain
        </li>
      </ul>
      <div className={classNames('input-wrapper', { error: error })}>
        <Input
          type="text"
          placeholder="Write here..."
          onChange={(value: string) => {
            setError(false);
            setValue(value);
          }}
        />
      </div>
      <div className="actions">
        <span onClick={props.onClose}>Cancel</span>
        <span
          onClick={() => {
            if (value.length <= 1) {
              setError(true);
              return;
            }
            if (inputType === 'domain' && !Common.validateTldr(value)) {
              setError(true);
              return;
            }
            props.onSubmit(value, inputType);
            setValue('');
            props.onClose();
          }}
        >
          Add
        </span>
      </div>
    </div>
  );
}
