import { Common } from 'common/services/common.service';

const TRENDS_COLORS = {
  GOOD: ['#24D5AA', '#2c2c30'],
  BAD: ['#F46666', '#2c2c30']
};

const isTrendUp = (data: any) => {
  if (data.length < 2) return true;
  let current = data[0];
  for (let i = 1; i < data.length; i++) {
    if (data[i] === current) {
      if (data.length === i + 1) return true;
      current = data[i];
      continue;
    }
    if (data[i] > current) return false;
    if (data[i] < current) return true;
  }
};
const getIsTrendUp = (series: any) => {
  const seriesValues = Array.from(series, (item: any) => item.y);
  const points = seriesValues.filter(item => item !== null).reverse();

  const ret = isTrendUp(points);
  // console.log('ret', ret);
  return ret;
  // return points.length < 2 || (points.length >= 2 && points[0] >= points[1]);
};
// const getIsTrendUp = (series: any) => {
//   const seriesValues = Array.from(series, (item: any) => item.y);
//   const points = seriesValues
//     .filter(item => item !== null)
//     .reverse()
//     .slice(0, 2);
//   return points.length < 2 || (points.length >= 2 && points[0] >= points[1]);
// };

const getChartSeries = (data: any, isAddedRed?: boolean) => {
  const series = data.map((item: any) => ({ y: item.value, ...item, ...(isAddedRed ? { isAddRed: isAddedRed } : {}) }));
  return series.slice(0, 6).reverse();
};

const getChartCategories = (series: any, xAxisFormatter: string) => {
  let categories;
  if (xAxisFormatter === 'm') {
    categories = series.map((item: any) => Common.getDateMonthYear(item.date));
  } else if (xAxisFormatter === 'y') {
    categories = series.map((item: any) => Common.getDateYear(item.date));
  }
  categories = series.map((item: any) => Common.getShortDate(item.date));

  return categories.reverse();
};
export { TRENDS_COLORS, getIsTrendUp, getChartSeries, getChartCategories };
