import { useEffect, useRef, useState } from 'react';
import { IReduxState } from 'app/store';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { setDeletePromptParams } from 'features/GridSlice';
import { useDispatch, useSelector } from 'react-redux';
import MetaGroupsPopup from 'pages/Settings/MetaGroups/MetaGroupsPopup/MetaGroupsPopup';
import { LoginService } from 'login/services/login.service';
import { resetMetaGroupsState, toggleMetaGroupPopup } from 'features/GroupsSlice';

import './MetaGroupsActions.scss';

const MetaGroupsActions = (props: any) => {
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  // const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const { user } = useSelector((state: IReduxState) => state.login);
  const { metaGroupPopupVisible } = useSelector((state: IReduxState) => state.groups);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    dispatch(
      setDeletePromptParams({
        category: 'meta-groups',
        index: null,
        ids: props.ids,
        message: 'Are you sure you would like to remove the following meta group?'
      })
    );
  };

  if (LoginService.isMemberOrBelow(user)) {
    return null;
  }

  const handlePopupVisible = (show: boolean) => {
    if (!show) {
      dispatch(resetMetaGroupsState());
    }
    dispatch(toggleMetaGroupPopup(show));
  };

  return (
    <div className="MetaGroupsActions" ref={ref}>
      <button className={`btn btn-main`} onClick={() => handlePopupVisible(true)}>
        Create New Meta Group
      </button>
      <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
        <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
          Actions
          <IconArrow />
        </div>
        <ul>
          <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
            Remove
          </li>
        </ul>
      </span>
      {metaGroupPopupVisible && (
        <MetaGroupsPopup open={metaGroupPopupVisible} onClose={() => handlePopupVisible(false)} />
      )}
    </div>
  );
};

export default MetaGroupsActions;
