import { useTopPanelContext } from '../TopPanelContext';
import {
  Text,
  DataPanel,
  Chart,
  IconRisk,
  LineChart,
  CHART_TYPES,
  TOOLTIP_TYPE,
  LINE_CHART_TYPE,
  IconTrend
} from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { TRENDS_COLORS, getIsTrendUp } from 'common/components/TopPanel/utils';
import { getChartSeries, getChartCategories } from 'common/components/TopPanel/utils';
import { Common } from 'common/services/common.service';
import useFilters from 'common/components/TopPanel/hooks/useFilters';

import './toppanel-action-items.scss';

// const texts = {
//   risky_connection: 'Risky Connection',
//   web_application: 'Web Application',
//   vulnerable_component: 'Vulnerable Component',
//   network: 'Network',
//   dns: 'DNS',
//   web: 'Web',
//   tls: 'TLS',
//   cloud: 'Cloud',
//   email: 'Email',
//   certificates: 'Certificates',
//   critical: 'Critical',
//   high: 'High',
//   medium: 'Medium',
//   low: 'Low'
// };
const dataPanelProps = {
  titleTextSize: 12,
  weight: 500,
  textSize: 24,
  textColor: '#FFFFFF',
  family: 'Rajdhani',
  titleStyle: { color: '#a6acbe' }
};

const textProps = {
  textSize: 12,
  textColor: '#FFFFFF',
  upper: true
};

const chartOptions = {
  chart: {
    type: CHART_TYPES.AREA,
    backgroundColor: '#2c2c30',
    // width: null,
    height: 100,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 10,
    marginBottom: 26
  },
  xAxis: {
    visible: true,
    tickWidth: 1,
    tickLength: 5,
    tickmarkPlacement: 'on',
    tickColor: '#B7BBC5'
  },
  yAxis: {
    gridLineWidth: 0,
    labels: {
      enabled: false
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 440
        },
        chartOptions: {
          chart: {
            marginBottom: 25,
            marginTop: 10
          },
          xAxis: {
            visible: true,
            labels: {
              enabled: false
            }
          }
        }
      },
      {
        condition: {
          minWidth: 441
        },
        chartOptions: {
          xAxis: {
            visible: true,
            labels: {
              enabled: true
            }
          }
        }
      }
    ]
  }
};

const ActionItemsExpand = () => {
  const { data, tab, isLoading } = useTopPanelContext();
  const { handleFiltersOnClick } = useFilters();

  let updatedChartOptions;
  let chartSeries;
  let chartColors;
  let isTrendUp;

  if (
    !!isLoading ||
    (tab === 'open' && !data.critical_action_items) ||
    (tab === 'closed' && !data.average_time_to_resolution)
  )
    return null;

  updatedChartOptions = {
    ...chartOptions,
    xAxis: {
      ...chartOptions.xAxis,
      categories: getChartCategories(
        tab === 'open' ? data.critical_action_items.data : data.average_time_to_resolution.data,
        'd'
      )
    }
  };
  chartSeries = getChartSeries(tab === 'open' ? data.critical_action_items.data : data.average_time_to_resolution.data);

  isTrendUp = getIsTrendUp(chartSeries);
  chartColors = isTrendUp ? TRENDS_COLORS.BAD : TRENDS_COLORS.GOOD;
  // }
  const last30Days =
    !isLoading && !!chartSeries && chartSeries.length > 1
      ? chartSeries[chartSeries.length - 1].y - chartSeries[chartSeries.length - 2].y
      : 0;

  const last30DaysProps =
    last30Days !== 0
      ? {
          isTrend: true,
          isTrendUp: isTrendUp,
          rightContentClassName: 'last-30',
          isUpArrowGreen: false
        }
      : {};
  return (
    <Grid container className="top-panel-action-items" sx={{ paddingLeft: 2.5 }}>
      <Grid item sx={{ flex: 0, paddingTop: 2.5 }}>
        <Grid container direction="column" className="panel-wrapper">
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} upper>
              ACTION ITEMS BY TYPE
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="action-items-wrapper">
              {[
                'risky_connection',
                'web_application',
                'vulnerable_component',
                'network',
                'dns',
                'tls',
                'web',
                'cloud',
                'email',
                'certificates'
              ].map((item: string) => {
                const moreProps = ['risky_connection', 'web_application', 'vulnerable_component'].includes(item)
                  ? { contentClassName: 'small-padding' }
                  : { contentClassName: 'content-class-name' };
                return (
                  <Grid item key={item} className="data-panel-item">
                    <DataPanel
                      id={item}
                      {...dataPanelProps}
                      {...moreProps}
                      title={Common.underscoreToTitle(item)}
                      //filters.includes(item)}
                      filterOnClick={(id, isToggle) => {
                        handleFiltersOnClick('type', Common.underscoreToTitle(id), 'actionItems');
                      }}
                    >
                      {data.action_items_by_type[item]}
                    </DataPanel>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 0 }}>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item className="distribution-by-urgency" sx={{ flex: 0, paddingTop: 2.5 }}>
        <Grid container direction="column" className="panel-wrapper">
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} upper>
              distribution by urgency
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="urgency-wrapper">
              {['critical', 'high', 'medium', 'low'].map((item: string) => {
                return (
                  <Grid item key={item}>
                    <Grid container direction="column" className="urgency-panel" sx={{ paddingRight: 3 }}>
                      <Grid item className="urgency-icon">
                        <IconRisk urgency={item} />
                      </Grid>
                      <Grid item className="action-items-urgency">
                        <DataPanel
                          id={item}
                          {...dataPanelProps}
                          // title={texts[item as keyof typeof texts]}
                          contentClassName="small-padding"
                          title={Common.underscoreToTitle(item, false)}
                          // isFiltered={filters.includes(item)}
                          filterOnClick={id => {
                            handleFiltersOnClick('urgency', Common.underscoreToTitle(id), 'actionItems');
                          }}
                        >
                          {data.distribution_by_urgency[item]}
                        </DataPanel>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 0 }}>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item className="critical-action-items" sx={{ flex: 1, paddingTop: 2.5 }}>
        <Grid container direction="column" sx={{ paddingRight: 2.5 }}>
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} upper>
              {`${tab === 'closed' ? 'AVERAGE TIME TO RESOLUTION' : 'Critical action items over time'} `}
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="chart-wrapper">
              {tab === 'open' ? (
                <Grid item className="critical-data-panel" sx={{ flex: 0 }}>
                  <DataPanel
                    id="last_30"
                    {...dataPanelProps}
                    title="Last 30 Days"
                    textSize={36}
                    {...last30DaysProps}
                    // contentClassName="small-padding"
                  >
                    {last30Days}
                  </DataPanel>
                </Grid>
              ) : (
                <Grid item className="critical-data-panel" sx={{ flex: 0 }}>
                  <DataPanel
                    id="last_30"
                    {...dataPanelProps}
                    title="Current Average"
                    textSize={36}
                    rightText="Days"
                    rightContentClassName="current-average-right-text"
                  >
                    {!!chartSeries.length ? chartSeries[chartSeries.length - 1].y : 0}
                  </DataPanel>
                </Grid>
              )}
              <Grid item sx={{ flex: 1, maxWidth: 550 }}>
                <Chart
                  chartOptions={updatedChartOptions}
                  chartType={CHART_TYPES.AREA}
                  colors={chartColors}
                  series={chartSeries}
                  tooltipType={TOOLTIP_TYPE.AREA}
                  isTooltipPointValueGreen={!isTrendUp}
                  isTooltipUpArrowGreen={false}
                  // isLogarithmic
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ActionItemsCollapse = () => {
  const { data, tab, isLoading } = useTopPanelContext();
  const { action_items_by_type, distribution_by_urgency } = data;

  if (
    !!isLoading ||
    (tab === 'open' && !data.critical_action_items) ||
    (tab === 'closed' && !data.average_time_to_resolution)
  )
    return null;

  const isUrgencyEmpty = Object.values(distribution_by_urgency).filter((item: any) => item !== 0);

  const chartSeries = getChartSeries(
    tab === 'open' ? data.critical_action_items.data : data.average_time_to_resolution.data
  );
  const isTrendUp = getIsTrendUp(chartSeries) || false;
  const last30Days =
    !!chartSeries && chartSeries.length > 1
      ? chartSeries[chartSeries.length - 1].y - chartSeries[chartSeries.length - 2].y
      : 0;

  return (
    <Grid container className="top-panel-collapse-wrapper">
      <Grid item className="top-panel-collapse-item" style={{ paddingLeft: 16 }}>
        <Text {...textProps}>Action Items By type</Text>
      </Grid>
      <Grid item>
        <Text {...textProps} weight={600} textSize={16} family="Rajdhani">
          {action_items_by_type?.count}
        </Text>
      </Grid>
      <div className="vertical-devider-collapse"></div>
      <Grid item className="top-panel-collapse-item">
        <Text {...textProps} upper>
          distribution by urgency
        </Text>
      </Grid>
      {!!isUrgencyEmpty.length && (
        <Grid item className="urgency-line" sx={{ position: 'relative', top: 0, flex: 1, maxWidth: 850 }}>
          <LineChart
            lineChartData={distribution_by_urgency}
            backgroundColor="#2c2c30"
            height={5}
            lineType={LINE_CHART_TYPE.URGENCY}
          />
        </Grid>
      )}
      <div className="vertical-devider-collapse"></div>
      <Grid item className="top-panel-collapse-item">
        <Text {...textProps} upper>
          {`${tab === 'closed' ? 'AVERAGE TIME TO RESOLUTION' : 'Critical action items over time'} `}
        </Text>
      </Grid>
      <Grid item className="top-panel-collapse-item last-30-days">
        {tab === 'open' ? (
          <Grid container>
            <Grid item>
              <Text {...textProps} weight={400} upper={false}>
                Last 30 Days
              </Text>
            </Grid>
            <Grid item sx={{ paddingLeft: 0.5, position: 'relative', top: -3, left: 0 }}>
              <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
                {last30Days}
              </Text>
            </Grid>
            {last30Days !== 0 && (
              <Grid item sx={{ paddingLeft: 0.5, paddingTop: 0.25 }}>
                <IconTrend isTrendUp={isTrendUp} isUpArrowGreen={false} />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container>
            <Grid item>
              <Text {...textProps} weight={400} upper={false}>
                Current Average
              </Text>
            </Grid>
            <Grid item sx={{ paddingLeft: 0.5, position: 'relative', top: -3, left: 0 }}>
              <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
                {chartSeries[chartSeries.length - 1].y}
              </Text>
            </Grid>

            <Grid item sx={{ paddingLeft: 0.5 }}>
              <Text weight={400} {...textProps} upper={false}>
                Days
              </Text>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const TopPanelActionItems = () => {
  // const [filters, setFilters] = useState(['risky_connection', 'web_application', 'vulnerable_component']);
  const { isOpen, data, isLoading } = useTopPanelContext();

  if (!!isLoading || !data || !Object.keys(data).length) return null;

  // return isOpen ? <ActionItemsExpand filters={filters} /> : <ActionItemsCollapse />;
  return isOpen ? <ActionItemsExpand /> : <ActionItemsCollapse />;
};

export default TopPanelActionItems;
