import { Button } from '@cyberpion/cyberpion-ui';
import './ScanStatus.scss';
import { RestApi } from 'common/services/rest-api.service';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateRescan } from 'features/GridSlice';
import { useState } from 'react';
import { ToolTip } from '../toolTip/toolTip';
import classNames from 'classnames';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

export const SCAN_STATUS = {
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed'
};

const iconPlay = (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
    <path
      d="M8.45 6.10622C8.91667 5.83679 8.91667 5.16321 8.45 4.89378L2.3 1.34308C1.83333 1.07365 1.25 1.41043 1.25 1.9493L1.25 9.0507C1.25 9.58956 1.83333 9.92635 2.3 9.65692L8.45 6.10622ZM8.45 6.10622L8.2 5.6732L8.45 6.10622L8.45 6.10622Z"
      stroke="#666666"
    />
  </svg>
);

export function ScanStatus(props: any, statusScan?: string) {
  const dispatch: AppDispatch = useDispatch();
  const { rows } = useSelector((state: any) => state.grid);
  const { user } = useSelector((state: any) => state.login);
  const [loading, setLoading] = useState<boolean>(false);
  const [startScanTime, setStartScanTime] = useState<Date>();
  const [scannedBy, setScannedBy] = useState<string | null>(null);

  const onClick = () => {
    setLoading(true);
    let url = `remediation/action-items/rescan/`;
    let data = {
      ids: [props.params.id]
    };

    RestApi.setData(url, data, 'POST').subscribe(
      (response: any) => {
        let index = rows.findIndex((row: any) => row.id === props.params.id);
        dispatch(updateRescan({ indexes: [{ index: index }], status: SCAN_STATUS.IN_PROGRESS }));
        setStartScanTime(new Date());
        setScannedBy(user.email);
        setLoading(false);
      },
      error => {
        setLoading(false);
      }
    );
  };

  const tt = !!startScanTime ? (
    <div>
      <div>{Moment(startScanTime).format('LLL')}</div>
      {scannedBy && <div style={{ paddingTop: 4 }}> By: {scannedBy}</div>}
    </div>
  ) : (
    <div>
      <div>{Moment(props.params.row.last_rescan_time).format('LLL')}</div>
      {props.params.row.last_rescan_by && <div style={{ paddingTop: 4 }}>By: {props.params.row.last_rescan_by}</div>}
    </div>
  );

  return (
    <div className="ScanStatus">
      {props.source?.tab === 'open' && !props.params.row.rescan_status && (
        <div className="scan-btn">
          {props.params?.fromPopside && <div className={classNames('icon-play')}>{iconPlay}</div>}
          <Button
            type="button"
            buttonStyle="secondary"
            text={!props.params?.fromPopside ? 'Rescan' : 'Run Rescan'}
            size="tiny"
            onClick={onClick}
            disabled={loading}
          />
        </div>
      )}
      {props.source?.tab === 'open' &&
        (props.params.row.rescan_status === SCAN_STATUS.IN_PROGRESS || statusScan === SCAN_STATUS.IN_PROGRESS) && (
          <div className="inprogress-wrapper">
            <Tooltip
              title={tt}
              placement="bottom"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#4D4C53',
                    color: '#FFFFFF',
                    padding: '3px 6px',
                    '& .MuiTooltip-arrow': {
                      color: '#4D4C53'
                    },
                    border: '2px solid black',
                    borderRadius: 1
                  }
                }
              }}
            >
              <div>
                <Button
                  type="button"
                  buttonStyle="secondary"
                  text={!props.params?.fromPopside ? 'In Progress' : 'Scan In Progress'}
                  className="scan-inprogress-btn"
                  size="tiny"
                  onClick={() => {}}
                  disabled
                />
              </div>
            </Tooltip>
          </div>
        )}
      {props.params.row.rescan_status === SCAN_STATUS.COMPLETED && (
        <div className="completed-wrapper">
          <Tooltip
            title={tt}
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#4D4C53',
                  color: '#FFFFFF',
                  padding: '3px 6px',
                  '& .MuiTooltip-arrow': {
                    color: '#4D4C53'
                  },
                  border: '2px solid black',
                  borderRadius: 1
                }
              }
            }}
          >
            <div>
              <Button
                type="button"
                buttonStyle="secondary"
                text="Completed"
                size="tiny"
                className="scan-completed-btn"
                disabled
                onClick={() => {}}
              />
            </div>
          </Tooltip>
          <ToolTip id="RESCAN" place="bottom" theme="dark" effect="solid" />
          {props.source?.tab === 'open' && (
            <img
              className={classNames({ disabled: loading })}
              src="/assets/images/reset.svg"
              alt="rescan"
              onClick={onClick}
              data-tip="RESCAN"
              data-for="RESCAN"
            />
          )}
        </div>
      )}
    </div>
  );
}
