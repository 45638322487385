import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'common/components/multiSelect/MultiSelect';
import { Image } from 'common/components/image/Image';
import { FiltersWrapper } from './filtersWrapper/FiltersWrapper';
import { Button as ViewsButton } from 'common/components/Button/Button';
import { Button } from '@mui/material';
import { Select } from 'common/components/select/Select';
import { GlobalSearch } from './globalSearch/GlobalSearch';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Common } from 'common/services/common.service';
import { SaveCustomView } from '../SavedViews/SaveCustomView';
import { toggleSavedViews } from 'features/ViewsSlice';
import { addDownloadFileData, setNewDownloads } from 'features/DownloadsSlice';
import { RestApi } from 'common/services/rest-api.service';

import './gridUtilities.scss';

export function GridUtilities(props: any) {
  const [printPDF, setPrintPDF] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const { count: rowsCount, initialPath, filters, search, order } = useSelector((state: any) => state.grid);
  const { isEdited } = useSelector((state: any) => state.views);
  const { globalFilter } = useSelector((state: any) => state.general);
  const { showColumnPicker } = useSelector((state: any) => state.columnPicker);
  const [saveCustomViewVisible, setSaveCustomViewVisible] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  let actionItemsPDFUrl = `${initialPath}detailed/?`;

  const flags = useFlags();

  useEffect(() => {
    const doWhenMessage = ({ data }: { data: any }) => {
      if (data === 'ActionItemsPDFPrintReady') {
        document.title = 'IONIX';
        setIsPDFLoading(false);
        setPrintPDF(false);
      }
    };
    window.addEventListener('message', doWhenMessage);
    return () => {
      window.removeEventListener('message', doWhenMessage);
    };
  }, []);

  function isDisabled() {
    return props.selectionModel.length === 0 ? 'gridUtilities-button-selection-disabled' : '';
  }

  function onSelectionChange() {
    props.onSelectionChange({
      ids: props.selectionModel,
      // originalIds: props.selectionRows,
      rows: props.loadedRows,
      apiGrid: props.apiGrid
    });
  }

  function callbackSelect(item: any) {
    props.onSelectionChange({
      ids: props.selectionModel,
      // originalIds: props.selectionRows,
      rows: props.loadedRows,
      moreData: item,
      apiGrid: props.apiGrid
    });
  }

  const AddedActions = props.addedActions;

  const handlePrintPDFClick = () => {
    document.title = initialPath.includes('/open') ? 'IONIX - Open Action Items' : 'IONIX - Close Action Items';
    setIsPDFLoading(true);
    setPrintPDF(true);
  };

  if (!!filters) {
    Object.keys(filters).forEach((item: any, index: number) => {
      actionItemsPDFUrl +=
        item +
        (filters[item].type !== '' ? filters[item].type : '') +
        '=' +
        filters[item].value +
        (Object.keys(filters).length - 1 === index ? '' : '&');
    });
  }
  if (!!search) {
    actionItemsPDFUrl += `${!!filters ? '&' : ''}search=${search}`;
  }
  if (!!order) {
    actionItemsPDFUrl += `${!!filters || !!search ? '&' : ''}ordering=${order}`;
  }
  if (!!globalFilter) {
    actionItemsPDFUrl += `${!!filters || !!search || !!order ? '&' : ''}group=${globalFilter}`;
  }

  const msspAccount = Common.getParamFromUrl('account');

  if (msspAccount) {
    actionItemsPDFUrl += `${!!filters || !!search || !!order || !!globalFilter ? '&' : ''}account=${msspAccount}`;
  }

  let PDFTooltip;
  if ((rowsCount > 1000 && !props.selectionModel?.length) || props.selectionModel?.length > 1000) {
    PDFTooltip = 'Can be printed up to 1000 action items';
  } else if (rowsCount === 0) {
    PDFTooltip = '';
  } else {
    PDFTooltip = 'Print PDF';
  }

  const shouldShowSavedViews = () => {
    return flags.isSavedViews && props.savedViews;
  };

  const src =
    !props.selectionModel && !props.selectionModel?.length
      ? `/action-items-pdf?fetchUrl=${actionItemsPDFUrl}`
      : `/action-items-pdf?fetchUrl=${actionItemsPDFUrl}&selectedModel=${props.selectionModel.join(',')}`;

  const handleCsvDownloadOnClick = async () => {
    setIsCSVLoading(true);
    const response = await RestApi.simpleAsyncGet(props.downloadCSVPath);
    setIsCSVLoading(false);
    dispatch(addDownloadFileData(response));
    dispatch(setNewDownloads(response?.id));
  };

  return (
    <div className={`gridUtilities`}>
      {printPDF && (
        <iframe
          id="action-items-pdf"
          name="action-items-pdf"
          src={src} // example URL: action-items-pdf?fetchUrl=action_items/open/detailed/?is_acknowledged=0&type__in=9&search=Login&ordering=-host
          style={{ position: 'absolute', top: -2000 }}
          title="ActionItemsPDF"
        />
      )}
      <div className="gridUtilities_wrapper">
        <div className="gridUtilities_left-side">
          {shouldShowSavedViews() && (
            <div style={{ position: 'relative' }}>
              <ViewsButton
                type="button"
                size="small"
                buttonStyle={'secondary'}
                text="Views"
                icon={'hamburgergray'}
                nestedJSXElement={
                  isEdited && (
                    <span
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setSaveCustomViewVisible(true);
                      }}
                    >
                      Save As...
                    </span>
                  )
                }
                onClick={() => dispatch(toggleSavedViews())}
                className={`saved-views-trigger ${
                  isEdited ? 'edited' : ''
                  /*props.savedViewsEdited && viewSelected !== -1 ? 'edited' : ''*/
                }`}
              />
              {saveCustomViewVisible && <SaveCustomView onClose={() => setSaveCustomViewVisible(false)} />}
            </div>
          )}
          <div className="gridUtilities_input-scope">
            <GlobalSearch /*onUpdateFilterGrid={props.onUpdateFilterGrid}*/ />
          </div>
          <FiltersWrapper
            // setIsStatusFilter={setIsStatusFilter}
            isMoreButton={props.isMoreButton}
            hideOrShowColumnsGrid={props.hideOrShowColumnsGrid}
            // onUpdateFilterGrid={props.onUpdateFilterGrid}
            // columnPicker={props.columnPicker}
            gridRef={props.apiGrid}
            columns={props.columns}
          />
        </div>
        <div className="gridUtilities_right-side">
          {props.buttonSelectionText && (
            <Button disabled={props.selectionModel.length === 0}>
              <div onClick={onSelectionChange} className={`gridUtilities-button-selection  ${isDisabled()}`}>
                {props.buttonSelectionText}
              </div>
            </Button>
          )}
          {showColumnPicker && (
            <MultiSelect
              // columnPicker={props.columnPicker}
              gridRef={props.apiGrid}
              columns={props.columns}
              identifier={props.identifier}
              // hideOrShowColumnsGrid={props.hideOrShowColumnsGrid}
            />
          )}
          {props.elementSelect && (
            <Select
              callbackChange={callbackSelect}
              options={props.elementSelect.options}
              header={props.elementSelect.header}
              ids={props.selectionModel}
              apiPath={props.elementSelect.apiPath}
              identifier={props.identifier}
              loadedRows={props.loadedRows}
              selectedRows={props.selectionModel}
            />
          )}
          {props.addedActions ? <AddedActions ids={props.selectionModel} /> : ''}
          <div className="gridUtilities-image-scope flex-wrapper">
            {props.downloadCSVPath &&
              props.isCSVDownloadable &&
              (isCSVLoading ? (
                <div className="csv-loading">
                  <CircularProgress />
                </div>
              ) : (
                <Tooltip
                  title="Download CSV"
                  placement="bottom-end"
                  arrow={true}
                  classes={{ tooltip: 'download-csv-tooltip' }}
                >
                  <div className="download-csv-link" onClick={handleCsvDownloadOnClick}>
                    <Image className="gridUtilities-csv" alt="CSV" icon={'csv'} type="svg" />
                  </div>
                </Tooltip>
              ))}
            {props.isActionItemsPDFDownloadable && (
              <>
                {isPDFLoading && (
                  <div className="pdf-loading">
                    <CircularProgress />
                  </div>
                )}
                {!isPDFLoading && (
                  <Tooltip
                    title={PDFTooltip}
                    placement="bottom-end"
                    arrow={true}
                    classes={{ tooltip: 'action-items-pdf-tooltip' }}
                  >
                    <span>
                      <div
                        onClick={handlePrintPDFClick}
                        className={`download-csv-link ${
                          (rowsCount > 1000 && !props.selectionModel?.length) ||
                          rowsCount === 0 ||
                          props.selectionModel?.length > 1000
                            ? 'diabled-icon-button'
                            : ''
                        }`}
                      >
                        <Image alt="PDF" icon={'pdf'} type="svg" />
                      </div>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            {/* <Image className="" alt="Tag" icon={"tag"} type="svg" /> */}
            {/* <IconCopy /> */}
          </div>
        </div>
      </div>
      {/* {(isStatusFilter.active || isStatusFilter.count >= 3) && (
        <div
          className={`gridUtilities-Expand-or-reduce `}
          onClick={onToggleGridUtilities}
        >
          <IconArrow active={toggle} toggleRotate={toggle} />
        </div>
      )} */}
    </div>
  );
}
