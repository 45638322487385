import React, { useEffect, useRef } from 'react';
import { Grid } from 'common/components/grid/Grid';
import { RestApi } from 'common/services/rest-api.service';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { IResponse, IRootColumnPicker } from 'common/interfaces/interface';
import { TabActionItems } from './components/tabActionItems/TabActionItems';
import { TabTechnologies } from './components/tabTechnologies/TabTechnologies';
import { IManageTabs } from './interface';
import { GridRowData, GridRowParams } from '@mui/x-data-grid-pro';
import { DefaultIcon } from 'common/components/manageNotData/components/defaultIcon/DefaultIcon';
import {
  disableURLChange,
  enableURLChange,
  removeAllRows,
  removeRowsOnly,
  setFields,
  setInitialPath
} from 'features/GridSlice';
import { AppDispatch } from 'app/store';

import { useDispatch, useSelector } from 'react-redux';
import { Common } from 'common/services/common.service';
import { Popup } from '@cyberpion/cyberpion-ui';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import { TabDiscoveryEvidence } from './components/tabDiscoveryEvidence/tabDiscoveryEvidence';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TabGraph } from './components/TabGraph/TabGraph';

export interface ITabGrid {
  // columnPicker: IColumnPicker[];
  // showColumnPicker: boolean;
  isMoreButton: boolean;
  isCSVDownloadable: boolean;
  count: number;
  columns: Array<any>;
  identifier: string;
}

export function ManageTabs(props: IManageTabs) {
  const [showGrid, setShowGrid] = React.useState<boolean>(false);
  const [selectTab, setSelectTab] = React.useState<string>('');
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const [showIconNotData, setShowIconNotData] = React.useState<boolean>(false);
  const [dataTabGrid, setDataTabGrid] = React.useState<ITabGrid>(initData());
  const [popupData, setPopupData] = React.useState<any>([]);
  const dispatch: AppDispatch = useDispatch();
  const { initialPath, rows } = useSelector((state: any) => state.grid);
  const isInitialMount = useRef(true);
  const flags = useFlags();
  //@description: Updates the data when the tab changes
  useEffect(() => {
    if (selectTab !== props.data.key && props.data.key !== '') {
      dispatch(removeRowsOnly());
      if (['evidence'].includes(props.data.key)) {
        dispatch(disableURLChange());
      } else {
        dispatch(enableURLChange());
      }
      getData();
    }
  });

  useEffect(() => {
    const popup = Common.getParamFromUrl('popup');
    if (rows.length && selectTab === 'tests' && popup && isInitialMount.current) {
      isInitialMount.current = false;
      const row = rows.filter((r: GridRowData) => r.name === popup)[0];
      onRowDoubleClick(undefined, row.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  function initData(): ITabGrid {
    return {
      // columnPicker: [],
      // showColumnPicker: false,
      isMoreButton: true,
      isCSVDownloadable: false,
      count: 0,
      columns: [],
      identifier: ''
    };
  }
  useEffect(() => {
    if (props.data.gridPath) {
      dispatch(setInitialPath(props.data.gridPath));
    }
  }, [dispatch, props.data.gridPath]);

  useEffect(() => {
    return () => {
      dispatch(removeAllRows());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //@description: ColumnPicker data request. And checking which columns to display
  function getData() {
    setShowGrid(false);
    setShowPopup(false);
    setSelectTab(props.data.key);

    const path = ['evidence', 'protocols'].includes(props.data.key)
      ? props.data.columnPickerPath.replace('%ASSET%', props.assetName)
      : props.data.columnPickerPath;

    if (!path) {
      setShowGrid(true);
      return;
    }

    RestApi.getData(path).subscribe(
      (columns: IRootColumnPicker) => {
        let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
        dispatch(setFields(fields));
        dispatch(setColumnPicker(columns.data));
        dispatch(setShowColumnPicker(columns.is_displayable));

        setDataTabGrid({
          // columnPicker: columns.data || [],
          // showColumnPicker: columns.is_displayable,
          isMoreButton: columns.is_more_button,
          isCSVDownloadable: columns.is_csv_downloadable,
          count: 0,
          identifier: columns.identifier_field,
          columns: ColumnsManage.createColumns(columns.identifier_field, columns.data || [], {
            page: 'singleAsset',
            category: props.data.category,
            tab: selectTab
          })
        });
        setShowIconNotData(false);
        setShowGrid(true);
      },
      error => {
        setShowIconNotData(true);
      }
    );
  }

  function onRowDoubleClick(params?: GridRowParams, id?: number) {
    if (props.data.key === 'tests') {
      const mobileMore = props.data.category === 'mobileAssets' ? 'mobile/' : '';
      RestApi.getData(`tests/${mobileMore}${params ? params.row[dataTabGrid.identifier] : id}/`).subscribe(
        (response: IResponse) => {
          const name = response.name;
          if (!!response.name) delete response.name;

          setPopupData({ data: response, title: 'Test', subtitle: name });
          setShowPopup(true);
        },
        error => {}
      );
    } else if (props.data.key === 'connections') {
      RestApi.getData(`connections/${params ? params.row[dataTabGrid.identifier] : id}/`).subscribe(
        (response: IResponse) => {
          setPopupData({
            data: response,
            title: 'Connections',
            subtitle: response.from_source,
            metadata: params ? params.row : {}
          });
          setShowPopup(true);
        },
        error => {}
      );
    } else if (props.data.key === 'vulnerabilities') {
      RestApi.getData(`vulnerabilities/${params ? params.row[dataTabGrid.identifier] : id}/`).subscribe(
        (response: IResponse) => {
          setPopupData({ data: response, title: 'Vulnerabilities', subtitle: response.title });
          setShowPopup(true);
        },
        error => {}
      );
    }
  }

  //@description: Switches tabs by type of information
  function switchTabs() {
    // ADDED TO SWITCH BETWEEN OLD/NEW EVIDENCE TAB VIA FEATURE FLAG
    let tabType = props.data.typeTab;
    if (flags.isDiscoveryEvidenceV2 && props.data.key === 'evidence') {
      tabType = 'evidence';
    }
    //
    switch (tabType) {
      case 'grid':
        // #TODO: remove this if
        if (
          initialPath.indexOf('connections') > -1 ||
          initialPath.indexOf('tests') > -1 ||
          initialPath.indexOf('vulnerabilities') > -1 ||
          initialPath.indexOf('protocols') > -1 ||
          initialPath.indexOf('evidence') > -1
        ) {
          return (
            <Grid
              onRowDoubleClick={onRowDoubleClick}
              columns={dataTabGrid.columns}
              count={dataTabGrid.count}
              isMoreButton={dataTabGrid.isMoreButton}
              isCSVDownloadable={dataTabGrid.isCSVDownloadable}
              // columnPicker={dataTabGrid.columnPicker}
              // showColumnPicker={dataTabGrid.showColumnPicker}
              identifier={dataTabGrid.identifier}
              showInfoPanel={initialPath.indexOf('evidence') > -1}
            />
          );
        }
        break;
      case 'evidence':
        if (initialPath.indexOf('evidence') > -1) {
          return <TabDiscoveryEvidence asset={props.assetName} />;
        }
        break;
      case 'graph':
        if (initialPath.indexOf('graph') > -1) {
          return <TabGraph asset={props.assetName} />;
        }
        break;
      case 'actionItems':
        if (initialPath.indexOf('action-items') > -1) {
          return (
            <TabActionItems
              selectTab={selectTab}
              isMoreButton={dataTabGrid.isMoreButton}
              isCSVDownloadable={dataTabGrid.isCSVDownloadable}
              // columnPicker={dataTabGrid.columnPicker}
              // showColumnPicker={dataTabGrid.showColumnPicker}
            />
          );
        }
        break;
      case 'technologies':
        if (initialPath.indexOf('technologies') > -1) {
          return (
            <TabTechnologies
              selectTab={selectTab}
              isMoreButton={dataTabGrid.isMoreButton}
              isCSVDownloadable={dataTabGrid.isCSVDownloadable}
              // columnPicker={dataTabGrid.columnPicker}
              // showColumnPicker={dataTabGrid.showColumnPicker}
            />
          );
        }
        break;
      default:
        return <div></div>;
    }
  }

  return showGrid ? (
    <>
      {switchTabs()}
      <Popup
        title={popupData.title}
        subtitle={popupData.subtitle}
        onClose={() => setShowPopup(false)}
        open={showPopup}
        list={popupData.data}
        metadata={popupData.metadata}
      />
    </>
  ) : showIconNotData ? (
    <div className="defaultIcon-notData">
      <DefaultIcon />
    </div>
  ) : (
    <div>Loading...</div>
  );
}
