import { IconActionItems } from '../Icons/IconActionItems/IconActionItems';
import { IconActiveProtect } from '../Icons/IconActiveProtect/IconActiveProtect';
import { IconDashboard } from '../Icons/IconDashboard/IconDashboard';
import { IconDiscover } from '../Icons/IconDiscover/IconDiscover';
import { IconAssess } from '../Icons/IconAssess/IconAssess';
import { IconSettings } from '../Icons/IconSettings/IconSettings';
import { ISidebarMenu } from './interface';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { IconAccounts } from '../Icons/IconAccounts/IconAccounts';
import { IconThreatCenter } from '../Icons/IconThreatCenter/IconThreatCenter';

export const MENU_ITEMS: ISidebarMenu = {
  dashboard: {
    title: 'Dashboard',
    key: 'dashboard',
    IconComponent: IconDashboard,
    link: '/pages/dashboard',
    permissionOpen: true,
    active: false,
    restricted: false,
    hideForMssp: true
  },
  actionItems: {
    title: 'Action Items',
    key: 'actionItems',
    defaultTab: 'actionItemsOpen',
    IconComponent: IconActionItems,
    link: '/pages/actionItems/open?filters=%7B"is_acknowledged"%3A0%7D',
    permissionOpen: true,
    active: false,
    restricted: false,
    hideForMssp: true
  },
  discovery: {
    title: 'Discovery',
    key: 'discovery',
    defaultTab: 'internalAssets',
    IconComponent: IconDiscover,
    link: '/pages/discovery/internalAssets',
    permissionOpen: true,
    active: false,
    restricted: false,
    hideForMssp: true
  },
  assessments: {
    title: 'Assessments',
    key: 'assessments',
    defaultTab: 'assesmentsInternalAssets',
    IconComponent: IconAssess,
    link: '/pages/assessments/internalAssets',
    permissionOpen: true,
    active: false,
    restricted: false,
    hideForMssp: true
  },
  activeProtection: {
    title: 'Active Protection',
    key: 'activeProtection',
    IconComponent: IconActiveProtect,
    link: '/pages/activeProtection?filters=%7B"is_actively_protected"%3A1%7D',
    permissionOpen: true,
    active: false,
    restricted: false,
    hideForMssp: true
  },
  accounts: {
    title: 'Accounts',
    key: 'accounts',
    IconComponent: IconAccounts,
    link: '/pages/accounts',
    permissionOpen: true,
    active: false,
    restricted: false,
    hideForMssp: false,
    showOnlyForMssp: true
  },
  threatCenter: {
    title: 'Threat Center',
    key: 'threatCenter',
    IconComponent: IconThreatCenter,
    link: '/pages/threatCenter',
    permissionOpen: true,
    active: false,
    restricted: false,
    hideForMssp: true,
    flag: 'isIonixThreatCenter'
  },
  settings: {
    title: 'Settings',
    key: 'settings',
    IconComponent: IconSettings,
    link: `/pages/${Paths[PathNames.userManagement]}`,
    memberLink: `/pages/${Paths[PathNames.manageFqdns]}`,
    msspLink: `/pages/${Paths[PathNames.tokens]}`,
    permissionOpen: true,
    active: false,
    restricted: false,
    hideForMssp: false
  }
};
