import { useEffect, useState } from 'react';
import { CarouselPanel } from '@cyberpion/cyberpion-ui';
import { RestApi } from 'common/services/rest-api.service';
import { useSelector } from 'react-redux';
import { Common } from 'common/services/common.service';
import './TickerPanelContainer.scss';

const TickerPanelContainer = () => {
  const [tickerData, setTickerData] = useState<any>([]);
  const [tickerDataWithFindings, setTickerDataWithFindings] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [findings, setFindings] = useState<any>({});
  const { globalFilter } = useSelector((state: any) => state.general);

  useEffect(() => {
    const path = 'threat-center/';
    RestApi.getData(path).subscribe((response: any) => {
      const rows20Items = response.results
        .sort((a: any, b: any) => new Date(b.creation_time).getTime() - new Date(a.creation_time).getTime())
        .slice(0, 20);
      setTickerData(rows20Items);
      setIsLoading(false);
    });
  }, []);

  const tickerItemOnClick = (uuid: string) => {
    const msspAccount = Common.getParamFromUrl('account');

    window.open(`/pages/threatCenter${msspAccount ? `?account=${msspAccount}` : ''}#${uuid}`, '_blank');
  };

  const handleFindingsOnClick = (url: string) => {
    const msspAccount = Common.getParamFromUrl('account');

    window.open(`${url}${msspAccount ? `&account=${msspAccount}` : ''}`, '_blank');
  };

  const getPromisesAPIs = () => {
    return tickerData.reduce((all: any, curr: any) => {
      return curr.findings_api_url || curr.potential_affected_api_url
        ? {
            ...all,

            ...(!!curr.findings_api_url
              ? {
                  [`${curr.uuid}_findings`]: (!!globalFilter
                    ? `${curr.findings_api_url}&group=${globalFilter}`
                    : curr.findings_api_url
                  ).replace('/api/v1/', '')
                }
              : {}),
            ...(!!curr.potential_affected_api_url
              ? {
                  [`${curr.uuid}_potential_affected`]: (!!globalFilter
                    ? `${curr.potential_affected_api_url}&group=${globalFilter}`
                    : curr.potential_affected_api_url
                  ).replace('/api/v1/', '')
                }
              : {})
          }
        : { ...all };
    }, {});
  };

  const fetchDependentData = async () => {
    const promisesAPIs = getPromisesAPIs();
    let responses: any = {};
    const responsePromisesAll: any = await RestApi.getPromiseAll(promisesAPIs);

    Object.entries(responsePromisesAll).forEach(([key, value]: any) => {
      const uuid = key.split('_')[0];
      responses = {
        ...responses,
        [uuid]: {
          ...responses[uuid],
          ...(key === `${uuid}_findings`
            ? {
                findings: value.count
              }
            : {}),
          ...(key === `${uuid}_potential_affected` ? { potential_affected: value.count } : {})
        }
      };
    });
    setFindings({ ...responses });
  };

  useEffect(() => {
    if (tickerData?.length) {
      fetchDependentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickerData, globalFilter]);

  useEffect(() => {
    if (Object.keys(findings).length) {
      const updatedData = tickerData.map((item: any) => {
        return {
          ...item,
          findings: findings[item.uuid]?.findings,
          potential_affected: findings[item.uuid]?.potential_affected
        };
      });
      setTickerDataWithFindings([...updatedData]);
      setIsLoading(false);
    }
  }, [tickerData, findings]);

  return (
    <div className="TickerPanelContainer">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div style={{}}>
          <CarouselPanel
            data={[...tickerDataWithFindings]}
            itemOnClick={tickerItemOnClick}
            findingsOnClick={handleFindingsOnClick}
          />
        </div>
        // <div />
      )}
    </div>
  );
};

export default TickerPanelContainer;
