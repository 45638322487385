import { useEffect, useState } from 'react';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { RestApi } from 'common/services/rest-api.service';
import { TopAssetsAtRisk } from './components/topAssetsAtRisk/TopAssetsAtRisk';
import { PieChartForm } from './components/pieChartForm/PieChartForm';
import { CriticalActionsOverTime } from './components/criticalActionsOverTime/CriticalActionsOverTime';
import { Map } from 'common/components/map/Map';
import { ActionItemsSummar } from './components/actionItemsSummar/ActionItemsSummar';
import { updateHeader } from 'common/services/common.service';
import TopPanel from 'common/components/TopPanel/TopPanel';
import { DownloadReports } from 'common/components/DownloadReports/DownloadReports';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import { ASMWrapper } from 'common/components/ASMWrapper/ASMWrapper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { resetColumnPicker } from 'features/columnPickerSlice';
import { DomainRegistrars } from './components/DomainRegistrars/DomainRegistrars';
import { GroupFilter } from 'common/components/GroupFilter/GroupFilter';
import { urlToStateConverter } from 'common/services/UrlToStateConverter.service';
import TickerPanelContainer from './components/TickerPanelContainer/TickerPanelContainer';
import './OldDashboard.scss';

export function OldDashboard() {
  const [response, setResponse] = useState<any>({});
  const [topPanelData, setTopPanelData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { globalFilter } = useSelector((state: any) => state.general);
  const { isLoggedIn } = useSelector((state: any) => state.login);
  const flags = useFlags();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(resetColumnPicker());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      updateHeader.sendHeaderText('Dashboard');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setIsLoading(true);
    setResponse({});
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  const initUrls = () => {
    return {
      action_items_graph: Paths[PathNames.action_items_graph],
      action_items_chart: Paths[PathNames.action_items_chart],
      action_items_pie: Paths[PathNames.action_items_pie],
      action_items_summary: Paths[PathNames.action_items_summary],
      connections_pie: Paths[PathNames.connections_pie],
      geomap: Paths[PathNames.geomap],
      hosts_pie: Paths[PathNames.hosts_pie],
      dashboard_summary: Paths[PathNames.dashboard_summary],
      top_assets: Paths[PathNames.top_assets]
    };
  };

  const getData = () => {
    setIsLoading(true);
    RestApi.getDataPageByFlowPaths(initUrls(), globalFilter).subscribe(
      (response: any) => {
        setResponse({ ...response });
        setTopPanelData(response.dashboard_summary);
        setIsLoading(false);
      },
      error => {}
    );
  };

  return (
    <div className="OldDashboard">
      <div className="dashboard-top">
        <GroupFilter
          type="dashboard"
          onChange={(value: string) => {
            urlToStateConverter.setQueryString({}, '', '', value, '');
          }}
        />
        <DownloadReports />
      </div>
      <TopPanel page={TOP_PANEL_PAGES.DASHBOARD} data={topPanelData} isLoading={isLoading} />

      <div className="dashboard-grid">
        {flags.isEmergingThreatsTicker && (
          <div className="dashboard-row">
            <div className="dashboard-item-ticker">
              <TickerPanelContainer />
            </div>
          </div>
        )}
        {!!flags.isAsmDashboardWidget && <ASMWrapper />}
        <div className="dashboard-row">
          <div className="dashboard-item-a">
            <ActionItemsSummar {...response.action_items_summary} />
            <TopAssetsAtRisk {...response.top_assets} />
          </div>
          <div className="dashboard-item-b">
            <CriticalActionsOverTime {...response.action_items_graph} />
            <div className="dashboard-item-d">
              <PieChartForm {...response.action_items_pie} />
              <PieChartForm {...response.hosts_pie} />
            </div>
          </div>
        </div>

        <div className="dashboard-foot-scope">
          {flags.isDomainRegistrarsGraph && <DomainRegistrars />}
          {response.geomap && <Map data={response.geomap} />}
          <PieChartForm {...response.connections_pie} />
        </div>
      </div>
    </div>
  );
}
