import queryString from 'query-string';

interface IQuery {
  [key: string]: any;
}

interface IFilter {
  type: string;
  value: string;
}

interface IFilters {
  [key: string]: IFilter;
}

class UrlToStateConverter {
  query: IQuery;

  getSearch() {
    this.query = queryString.parse(window.location.search);
    return this.query.search;
  }

  getSort() {
    this.query = queryString.parse(window.location.search);
    return this.query.ordering;
  }

  getGroup() {
    this.query = queryString.parse(window.location.search);
    return this.query.group;
  }

  getAccount() {
    this.query = queryString.parse(window.location.search);
    return this.query.account;
  }

  getFields() {
    this.query = queryString.parse(window.location.search);
    return this.query.fields;
  }

  getFilters() {
    this.query = queryString.parse(window.location.search);
    if (!this.query.filters) {
      return {};
    }
    const filters: IFilters = {};
    const parsedQuery = JSON.parse(this.query.filters);
    for (const filter in parsedQuery) {
      const [filter_name, filter_type] = filter.split('__');
      const filter_value = parsedQuery[filter];
      filters[filter_name] = { type: `${filter_type ? `__${filter_type}` : ''}`, value: filter_value };
    }
    return filters;
  }

  setQueryString(filters: IFilters, search: string, order: string, group: string, fields: string) {
    const url = new URL(window.location.href);

    if (Object.keys(filters).length) {
      const obj: IQuery = {};
      for (const filter in filters) {
        obj[`${filter}${filters[filter].type}`] = filters[filter].value;
      }
      url.searchParams.set('filters', JSON.stringify(obj));
    } else {
      url.searchParams.delete('filters');
    }

    if (search) {
      url.searchParams.set('search', search);
    } else {
      url.searchParams.delete('search');
    }

    if (fields) {
      url.searchParams.set('fields', fields);
    } else {
      url.searchParams.delete('fields');
    }

    if (order) {
      url.searchParams.set('ordering', order);
    } else {
      url.searchParams.delete('ordering');
    }

    if (group) {
      url.searchParams.set('group', group);
    } else {
      url.searchParams.delete('group');
    }
    window.history.replaceState(null, '', url);
  }
}

export const urlToStateConverter = new UrlToStateConverter();
