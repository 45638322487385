import { useState } from 'react';
import './AddAccount.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';
import { Common } from 'common/services/common.service';
import { MainInputs } from '../EditAccount/MainInputs/MainInputs';
import { AdditionalInputs } from '../EditAccount/AdditionalInputs/AdditionalInputs';
import { AddAccountConfirmation } from './AddAccountConfirmation/AddAccountConfirmation';
import { AppLoading } from '../appLoading/appLoading';
import { useDispatch } from 'react-redux';
import { addMSSPAccount, toggleErrorModal } from 'features/MSSPSlice';

interface IInput {
  value: string;
  error: boolean;
}

interface IAPIDataObj {
  display_name: string;
  main_domain: string;
  linked_in?: string;
  additional_names?: string[];
  additional_domains?: string[];
}
export interface IAccount {
  display_name: IInput;
  main_domain: IInput;
  linked_in: IInput;
  additional_names: IInput[];
  additional_domains: IInput[];
}

export const initData = (initialValue?: string) => {
  return {
    display_name: initInputObject(initialValue),
    main_domain: initInputObject(initialValue),
    linked_in: initInputObject(initialValue),
    additional_names: [initInputObject(initialValue)],
    additional_domains: [initInputObject(initialValue)]
  };
};

export const initInputObject = (initialValue?: string) => {
  return {
    value: initialValue || '',
    error: false
  };
};

export function AddAccount(props: any) {
  // const [domainInputs, setDomainInputs] = useState<number>(1);
  const [data, setData] = useState<IAccount>(initData());
  const [showSetupConfirmation, setShowSetupConfirmation] = useState<Boolean>(false);
  const [confirmationData, setConfirmationData] = useState<any>({});
  const [loading, setLoading] = useState<Boolean>(false);
  const dispatch = useDispatch<any>();

  const onSave = async () => {
    let error = false;
    const copy = { ...data };
    if (copy.display_name.value.length < 2) {
      error = true;
      copy.display_name.error = true;
    }
    if (copy.linked_in.value && !Common.validateLinkedinAccount(copy.linked_in.value)) {
      error = true;
      copy.linked_in.error = true;
    }
    // copy.main_domain.forEach(domain => {
    if (!Common.validateDomainName(copy.main_domain.value)) {
      error = true;
      copy.main_domain.error = true;
    }
    // });
    copy.additional_names.forEach(name => {
      if (copy.additional_names.length === 1 && copy.additional_names[0].value === '') {
        // OK
      } else if (name.value.length < 2) {
        error = true;
        name.error = true;
      }
    });
    copy.additional_domains.forEach(domain => {
      if (copy.additional_domains.length === 1 && copy.additional_domains[0].value === '') {
        // OK
      } else if (!Common.validateDomainName(domain.value)) {
        error = true;
        domain.error = true;
      }
    });
    if (error) {
      setData(copy);
    } else {
      setLoading(true);
      const _data: IAPIDataObj = {
        display_name: data.display_name.value,
        main_domain: data.main_domain.value
      };
      if (data.linked_in.value) {
        _data.linked_in = data.linked_in.value;
      }
      const an = data.additional_names.map(an => an.value).filter(an => an.length > 0);
      const ad = data.additional_domains.map(ad => ad.value).filter(an => an.length > 0);
      if (an.length) {
        _data.additional_names = an;
      }
      if (ad.length) {
        _data.additional_domains = ad;
      }
      const res = await dispatch(addMSSPAccount(_data));
      if (!res.error && typeof res.payload !== 'string' && !res.payload?.detail) {
        setShowSetupConfirmation(true);
        setConfirmationData(res);
      } else {
        dispatch(toggleErrorModal(typeof res.payload === 'string' ? res.payload : ''));
        // props.onClose();
        // dispatch(showError(res.error.message));
      }
      setLoading(false);
    }
  };

  return (
    <div className="AddAccount">
      <h2>Add Account</h2>
      <AddAccountConfirmation
        isVisible={showSetupConfirmation}
        onCancel={() => setShowSetupConfirmation(false)}
        data={confirmationData}
        onClose={props.onClose}
      />
      <div className={classNames('add-account-wrapper', { visible: !showSetupConfirmation })}>
        <h3>{data.display_name.value || 'New account details'}</h3>
        <div className="scrollbar-common">
          <div className="main-scrollable-area">
            <div style={{ height: '100%', overflow: 'auto' }}>
              <MainInputs data={data} onChange={setData} />
              <AdditionalInputs data={data} onChange={setData} displayInfo />
            </div>
          </div>
        </div>
        <div className={classNames('buttons-container', { loading: loading })}>
          {loading && (
            <div style={{ height: '100%', width: 100, position: 'absolute', top: '-10px' }}>
              <AppLoading transparent />
            </div>
          )}
          <Button text="Cancel" onClick={props.onClose} type="button" buttonStyle="secondary" size={'medium'} />
          <Button text="Confirm Account" onClick={onSave} type="button" buttonStyle="main" size={'medium'} />
        </div>
      </div>
    </div>
  );
}
