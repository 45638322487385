import { LabelWithConfidence } from '@cyberpion/cyberpion-ui';
import './SeedDataAdditionalInfo.scss';
import { useClickOutside } from 'hooks/useClickOutside';
import { useRef } from 'react';

export function SeedDataAdditionalInfo(props: any) {
  const ref = useRef(null);

  useClickOutside(ref, props.onClose);

  return (
    <div className="SeedDataAdditionalInfo" style={props.style} ref={ref}>
      <h4>MetLife</h4>
      <LabelWithConfidence level={'High'} label={'High Confidence'} />
      <h5>Details</h5>
      <ul>
        <li>
          <h6>Country</h6>
          <p>Israel</p>
        </li>
        <li>
          <h6>Social</h6>
          <p>
            <img src="#" alt="Favicon" />
            https://www.facebook.com/metlife
          </p>
        </li>
      </ul>
    </div>
  );
}
