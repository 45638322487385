export const PathNames = {
  inventoryMetaData: 'inventoryMetaData',
  internalAssets: 'internalAssets',
  internalAssetsColumns: 'internalAssetsColumns',
  internalAssetsSummary: 'internalAssetsSummary',
  digitalSupplyChain: 'digitalSupplyChain',
  digitalSupplyChainColumns: 'digitalSupplyChainColumns',
  digitalSupplyChainSummary: 'digitalSupplyChainSummary',
  loginAssets: 'loginAssets',
  loginAssetsColumns: 'loginAssetsColumns',
  loginAssetsSummary: 'loginAssetsSummary',
  publicCloud: 'publicCloud',
  publicCloudColumns: 'publicCloudColumns',
  unlinkedAssets: 'unlinkedAssets',
  mobileAssets: 'mobileAssets',
  unlinkedAssetsColumns: 'unlinkedAssetsColumns',
  mobileAssetsColumns: 'mobileAssetsColumns',
  unlinkedAssetsSummary: 'unlinkedAssetsSummary',
  mobileAssetsSummary: 'mobileAssetsSummary',
  managedDomains: 'managedDomains',
  certificates: 'certificates',
  managedDomainsColumns: 'managedDomainsColumns',
  certificatesColumns: 'certificatesColumns',
  managedDomainsSummary: 'managedDomainsSummary',
  certificatesSummary: 'certificatesSummary',
  activeProtection: 'activeProtection',
  activeProtectionColumns: 'activeProtectionColumns',
  activeProtectionSummary: 'activeProtectionSummary',
  actionItemsMetaData: 'actionItemsMetaData',
  actionItemsClose: 'actionItemsClose',
  actionItemsCloseColumns: 'actionItemsCloseColumns',
  actionItemsCloseSummary: 'actionItemsCloseSummary',
  actionItemsOpen: 'actionItemsOpen',
  actionItemsOpenColumns: 'actionItemsOpenColumns',
  actionItemsOpenSummary: 'actionItemsOpenSummary',
  assesments: 'assesments',
  assesmentsInternalAssets: 'assesmentsInternalAssets',
  assesmentsDigitalSupplyChain: 'assesmentsDigitalSupplyChain',
  assesmentsDeepDarkMonitoring: 'assesmentsDeepDarkMonitoring',
  assesmentsLeakedCredentials: 'assesmentsLeakedCredentials',
  assesmentsInternalAssetsColumns: 'assesmentsInternalAssetsColumns',
  assesmentsDigitalSupplyChainColumns: 'assesmentsDigitalSupplyChainColumns',
  assesmentsVendorMonitoringColumns: 'assesmentsVendorMonitoringColumns',
  assesmentsVendorMonitoring: 'assesmentsVendorMonitoring',
  assesmentsOrgScoreCards: 'assesmentsOrgScoreCards',
  assesmentsOrgScoreSummary: 'assesmentsOrgScoreSummary',
  assesmentsOrgScoreSubsidiaries: 'assesmentsOrgScoreSubsidiaries',
  assesmentsOrgScoreColumns: 'assesmentsOrgScoreColumns',
  assesmentsOrgScoreCategoryData: 'assesmentsOrgScoreCategoryData',
  assesmentsOrgScoreIssuesColumns: 'assesmentsOrgScoreIssuesColumns',
  assesmentsOrgScoreIssuesData: 'assesmentsOrgScoreIssuesData',
  assesmentsDeepDarkMonitoringColumns: 'assesmentsDeepDarkMonitoringColumns',
  assesmentsLeakedCredentialsColumns: 'assesmentsLeakedCredentialsColumns',
  assesmentsMetaData: 'assesmentsMetaData',
  users: 'users',
  logout: 'logout',
  /**
   *  Dashboard
   */
  action_items_chart: 'action_items_chart',
  action_items_graph: 'action_items_graph',
  action_items_pie: 'action_items_pie',
  action_items_graph_open: 'action_items_graph_open',
  action_items_graph_close: 'action_items_graph_close',
  action_items_summary: 'action_items_summary',
  connections_pie: 'connections_pie',
  geomap: 'geomap',
  hosts_pie: 'hosts_pie',
  dashboard_summary: 'dashboard_summary',
  top_assets: 'top_assets',
  /**
   *  Settings
   */
  scanInfo: 'scanInfo',
  integrations: 'integrations',
  subsidiaries: 'subsidiaries',
  groups: 'groups',
  metaGroups: 'metaGroups',
  notifications: 'notifications',
  userManagement: 'userManagement',
  domainRestriction: 'domainRestriction',
  ipRestriction: 'ipRestriction',
  ipRanges: 'ipRanges',
  auditLogs: 'auditLogs',
  manageFqdns: 'manageFqdns',
  tokens: 'tokens',
  customPort: 'customPort',
  seedData: 'seedData',
  services: 'services',
  integrationJira: 'integrationJira',
  integrationSplunk: 'integrationSplunk',
  integrationSentinel: 'integrationSentinel',
  integrationServiceNow: 'integrationServiceNow'
};
