import { Common } from 'common/services/common.service';
import './rowCount.scss';
interface IRowCount {
  conut: number;
  typeData: string;
  selectedCount?: number;
}
export function RowCount(props: IRowCount) {
  return (
    <div>
      <div className="rowCount-wrapper">
        <div className="row-count">Showing {Common.numberWithCommas(props.conut)} Rows</div>
        {props.selectedCount ? <span>&nbsp;({props.selectedCount} Selected)</span> : ''}
      </div>
    </div>
  );
}
