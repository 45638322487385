import { useState } from 'react';
import { IconArrow } from '../Icons/IconArrow/IconArrow';
import { AddRemoveTags } from '../AddRemoveTags/AddRemoveTags';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import classNames from 'classnames';
import { useComponentVisible } from 'common/hooks/useComponentVisible';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { queryBuilder } from '../grid/query-builder.service';
import { Common } from 'common/services/common.service';
import { SCAN_STATUS } from '../ScanStatus/ScanStatus';
import { RestApi } from 'common/services/rest-api.service';
import { addDownloadFileData, setNewDownloads } from 'features/DownloadsSlice';

import './select.scss';

interface IOption {
  IconComponent?: any;
  data?: any;
  label: string;
  ff?: string;
}

interface ISelect {
  options: IOption[];
  header: string;
  apiPath: string;
  callbackChange: (label: IOption) => void;
  ids: number[];
  identifier: string;
  loadedRows: any[];
  selectedRows?: any[];
}

export function Select(props: ISelect) {
  const [showTagsDropDown, setShowTagsDropDown] = useState<boolean>(false);
  const [tagsMethod, setTagsMethod] = useState<string>('');
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const flags: any = useFlags();
  const dispatch = useDispatch<any>();

  function handleChange() {
    if (isComponentVisible) {
      setShowTagsDropDown(false);
    }
    setIsComponentVisible(!isComponentVisible);
  }

  async function onChange(item: IOption) {
    const isMsspAccount = Common.getParamFromUrl('account');
    if (item.label === 'Add Tags') {
      setTagsMethod('add');
      setShowTagsDropDown(true);
    } else if (item.label === 'Remove Tags') {
      setTagsMethod('remove');
      setShowTagsDropDown(true);
    } else if (item.label === 'Export Discovery Evidence') {
      let csv;
      if (props.ids.length) {
        csv = Common.AddMsspAccountIfNeeded(
          `discovery/org-assets/export-discovery-evidence/?asset__in=${props.ids.join(',')}&format=csv`
        );
      } else {
        csv = `discovery/org-assets/export-discovery-evidence/?${queryBuilder.getQuery().split('?')[1]}&format=csv${
          isMsspAccount ? `&account=${isMsspAccount}` : ''
        }`;
      }
      const response = await RestApi.simpleAsyncGet(csv);
      dispatch(addDownloadFileData(response));
      dispatch(setNewDownloads(response?.id));
    } else {
      setIsComponentVisible(false);
      props.callbackChange(item);
    }
  }

  const isDisabled = (item: any) => {
    if (item.label === 'Rescan') {
      if (
        props.loadedRows.filter(
          (row: any) => row.rescan_status === SCAN_STATUS.IN_PROGRESS && props.selectedRows?.includes(row.id)
        ).length
      ) {
        return true;
      }
      if (!props.selectedRows?.length) {
        return true;
      }
      return false;
    }
    if (['Acknowledge', 'Unacknowledge'].includes(item.label) && !props.selectedRows?.length) {
      return true;
    }
    return false;
  };

  return (
    <div className="select-wrapper" ref={ref}>
      <div className="select-header-wrapper" onClick={handleChange} data-testid={props.header}>
        <div className="select-header">{props.header}</div>
        <IconArrow active={isComponentVisible} toggleRotate={isComponentVisible} />
      </div>
      {showTagsDropDown && (
        <AddRemoveTags
          method={tagsMethod}
          tags={columnPicker.filter((c: any) => c.name === 'tags')[0]}
          ids={props.ids || []}
          onClose={() => setShowTagsDropDown(false)}
          apiPath={props.apiPath}
          identifier={props.identifier}
          loadedRows={props.loadedRows}
        />
      )}
      {isComponentVisible && (
        <div className="select-body-wrapper">
          {props.options
            .filter((option: IOption) => (option.ff && flags[option.ff]) || !option.ff)
            .map((item: IOption, key: number) => {
              return (
                <div className={classNames('select-item-scope', { disabled: isDisabled(item) })} key={key}>
                  {item.IconComponent && <item.IconComponent />}
                  <div className={classNames('select-label')} onClick={() => onChange(item)}>
                    {item.label}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
