import { RestApi } from 'common/services/rest-api.service';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Common } from 'common/services/common.service';
import { GridUtilities } from 'common/components/grid/components/gridUtilities/GridUtilities';
import { IActionItems, ISwitchTabs } from 'pages/inventory/singleAsset/interface';
import { ManageNotData } from 'common/components/manageNotData/ManageNotData';
import { getRows } from 'features/GridSlice';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { IconActiveProtection } from 'common/components/Icons/IconActiveProtection/IconActiveProtection';
import { PopSide } from 'common/components/PopSide/PopSide';
import ActionItemsPopside from 'pages/action-items/popside/ActionItemsPopside';

import './tabActionItems.scss';
import { useHistory, useLocation } from 'react-router-dom';

export function TabActionItems(props: ISwitchTabs) {
  const [visible, setVisible] = React.useState(false);
  const [dataPopUp, setDataPopUp] = React.useState<IActionItems>();
  const { rows, nextPath } = useSelector((state: IReduxState) => state.grid);
  const location = useLocation();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getRows());
  }, [dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('open_popside') && queryParams.get('open_popside') === 'true' && queryParams.has('ai_id')) {
      const url = `remediation/action-items/${
        queryParams.get('is_open') === 'true' ? 'open' : 'closed'
      }/${queryParams.get('ai_id')}/`;
      RestApi.getData(url).subscribe(
        (response: any) => {
          setDataPopUp(response);
          queryParams.delete('open_popside');
          queryParams.delete('is_open');
          queryParams.delete('ai_id');
          history.replace({
            search: queryParams.toString()
          });
          setVisible(true);
        },
        error => {}
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //@description: Sends request for more data
  function handleOnRowsScrollEnd() {
    if (nextPath) {
      dispatch(getRows());
    }
  }

  function openPopUp(item: IActionItems) {
    const url = `remediation/action-items/${item.last_closed_at ? 'closed' : 'open'}/${item.id}/`;
    RestApi.getData(url).subscribe((response: any) => {
      setDataPopUp(response);
      setVisible(true);
    });
  }

  function handleCallback() {}

  function manageMessages() {
    return <h4>Loading...</h4>;
  }

  if (rows[0] && !rows[0].summary) {
    return null;
  }
  return (
    <div className="tabActionItems">
      <GridUtilities
        // columnPicker={props.columnPicker}
        // showColumnPicker={props.showColumnPicker}
        isMoreButton={props.isMoreButton}
        parentCallback={handleCallback}
        // onUpdateFilterGrid={handleUpdateGrid}
        // downloadCSVPath={csvDownloadPath}
        isCSVDownloadable={props.isCSVDownloadable}
      />
      <div className="scrollbar-common tabActionItems-scope">
        <div id="scrollableDiv" style={{ height: '100%', overflow: 'auto' }}>
          {rows.length === 0 ? (
            <ManageNotData type={props.selectTab} />
          ) : (
            <InfiniteScroll
              dataLength={rows.length}
              next={handleOnRowsScrollEnd}
              hasMore={!!nextPath}
              loader={manageMessages()}
              scrollableTarget="scrollableDiv"
            >
              {rows.map((item: IActionItems, index: number) => (
                <React.Fragment key={index}>
                  <div className="tabActionItems-items-scope">
                    <div
                      className="tabActionItems-border-left"
                      style={{
                        background: item.last_closed_at ? '#8C909A' : Common.getColorByUrgency(item.urgency.type)
                      }}
                    ></div>
                    <div className="tabActionItems-head">
                      <div className="">
                        {item.urgency.is_active_protection && (
                          <span className="tabActionItems-head-value">
                            <IconActiveProtection />
                            <span className="tabActionItems-headline">Active Protection Applied</span>
                            <div className="tabActionItems-head-circle"></div>
                          </span>
                        )}
                        <span className="tabActionItems-headline">Type</span>
                        <span className="tabActionItems-head-value">
                          {item.type}
                          <div className="tabActionItems-head-circle"></div>
                          {item.last_closed_at ? <>Closed since {Common.getDateFormat(item.last_closed_at)}</> : 'Open'}
                        </span>
                      </div>
                      <div className="tabActionItems-head-header-right-scope">
                        <div className="">
                          <span className="tabActionItems-headline">Urgency</span>
                          <span className="tabActionItems-head-value">{item.urgency.urgency}</span>
                        </div>
                        <div className="tabActionItems-head-circle"></div>
                        <div className="">{`Open Date ${Common.getDateFormat(item.first_opened_at)}`}</div>
                        {item.last_opened_at && (
                          <>
                            <div className="tabActionItems-head-circle"></div>
                            <div className="">{`Last Opened Date ${Common.getDateFormat(item.last_opened_at)}`}</div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="tabActionItems-item-body-scope">
                      <div className="tabActionItems-body-title">{item.title}</div>
                      <div className="tabActionItems-body-summary">{item.summary}</div>
                    </div>
                    <div className="tabActionItems-foot-scope">
                      {/* <Assignee assignee={item.assignee} id={item.id} /> */}
                      <div className="tabActionItems-show-more" onClick={() => openPopUp(item)}>
                        Show More
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
      {visible && (
        <div className="action-items-popside">
          <PopSide visible={visible} onHide={setVisible}>
            {/* <ActionItemsInfo data={dataRow} setVisible={setVisible} /> */}
            <ActionItemsPopside data={dataPopUp} onHide={setVisible} showActions={false} />
          </PopSide>
        </div>
      )}
      {/* <PopUp setVisible={setVisible} visible={visible} data={dataPopUp}>
        <ActionItemsInfo data={dataPopUp} setVisible={setVisible} hideShowMore={true} />
      </PopUp> */}
    </div>
  );
}
